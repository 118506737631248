var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-button",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      attrs: { color: "light" },
      on: { click: _vm.returnToWaitingList },
    },
    [
      _vm._v(" Give back to queue "),
      _c("fv-icon", { staticClass: "mx-1", attrs: { icon: "queue-24" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }