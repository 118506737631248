import ConfettiGenerator from 'confetti-js';

const generateConfetti = (node, options) => {
  const opts = {
    target: node,
    ...options,
  };
  const confetti = new ConfettiGenerator({
    ...opts,
    colors: [[0, 190, 214], [240, 151, 164], [16, 28, 78], [59, 191, 173]],
  });
  return confetti;
};
export default generateConfetti;
