<template>
  <base-button v-tooltip="tooltip" color="light" @click="returnToWaitingList">
    Give back to queue
    <fv-icon icon="queue-24" class="mx-1" />
  </base-button>
</template>

<script>
import { WaitingRoomApi } from '@/api';

export default {
  components: {},
  props: {
    appointmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tooltip:
        'Removes you from this meeting and sends appointment to waiting list. Draft journal entries will be saved.',
    };
  },
  methods: {
    async returnToWaitingList() {
      try {
        await WaitingRoomApi.deleteQueueAssignee(this.appointmentId);
        this.$router.push({
          name: 'waiting-room',
        });
      } catch (error) {
        this.$notify({
          group: 'error',
          title: `Failed to return appointment ${this.appointmentId} to waiting list`,
          message: error.message,
        });
      }
    },
  },
};
</script>
