var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-6" },
    [
      _c(
        "div",
        { staticClass: "flex flex-col space-y-2" },
        [
          _vm.enableUserToUseApoExIntegration
            ? _c(
                "base-button",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.generateApoExPrescriptionLink },
                },
                [_vm._v("Generate ApoEx e-presciption ")]
              )
            : _vm._e(),
          _vm.signedStatus
            ? _c(
                "div",
                [
                  _c("title-row", [_vm._v("Prescription information")]),
                  _c("p", { staticClass: "py-4" }, [
                    _vm._v(_vm._s(_vm.prescription)),
                  ]),
                ],
                1
              )
            : _c("textarea-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                ref: "prescriptionInput",
                attrs: {
                  id: "prescription",
                  disabled: _vm.disableInput,
                  rows: 8,
                  placeholder: "Enter prescription information",
                },
                on: { input: _vm.autoSave },
                model: {
                  value: _vm.prescription,
                  callback: function ($$v) {
                    _vm.prescription = $$v
                  },
                  expression: "prescription",
                },
              }),
          _vm.apoexPrescriptionLinkGenerated &&
          _vm.enableUserToUseApoExIntegration
            ? _c(
                "base-button",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.populateApoExPrescriptionDetails },
                },
                [_vm._v("Populate ApoEx e-prescription details ")]
              )
            : _vm._e(),
          _vm.storeJournal.prescription
            ? _c(
                "div",
                {
                  staticClass:
                    "inline-flex rounded-lg bg-primary-lighter bg-opacity-50 px-4 py-3 text-navy",
                },
                [
                  _c(
                    "p",
                    { staticClass: "inline-flex items-center space-x-1" },
                    [
                      _c("span", [
                        _vm._v("Last saved at " + _vm._s(_vm.formattedDate)),
                      ]),
                      _c("fv-icon", {
                        staticClass: "text-fv-green",
                        attrs: { size: "md", icon: "check-mark" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.signedStatus && _vm.showSignButton
            ? _c(
                "div",
                [
                  _c(
                    "base-button",
                    {
                      attrs: { color: "primary", disabled: _vm.disableSigned },
                      on: { click: _vm.signPrescription },
                    },
                    [_vm._v(" Sign prescription ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showApoExApiKeyModal },
          on: {
            close: function ($event) {
              _vm.showApoExApiKeyModal = false
            },
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                "h2",
                {
                  staticClass:
                    "mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl",
                },
                [_c("span", [_vm._v("ApoEx API Key")])]
              ),
              _c(
                "div",
                { staticClass: "mb-4 rounded-lg bg-gray-50 p-4 text-gray-600" },
                [
                  _c("p", [
                    _vm._v(
                      " To generate an ApoEx e-prescription you will need to supply your API key below and submit. "
                    ),
                  ]),
                ]
              ),
              _c("base-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                staticClass: "w-1/2",
                attrs: {
                  id: "apoexApiKey",
                  type: "text",
                  placeholder: "Enter your API key",
                  required: "",
                },
                model: {
                  value: _vm.apoexApiKey,
                  callback: function ($$v) {
                    _vm.apoexApiKey = $$v
                  },
                  expression: "apoexApiKey",
                },
              }),
            ]
          }),
          _c(
            "div",
            {
              staticClass: "flex w-full justify-end space-x-2 p-2",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.showApoExApiKeyModal = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.saveApoExApiKey },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }