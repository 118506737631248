<template>
  <div class="flex flex-col space-y-2">
    <base-label for="appointmentType">
      Area for discussion <span class="text-gray-700">*</span>

      <span
        v-if="showValidationErrors && error"
        class="text-red-800 p-2 ml-8 font-semibold text-center"
      >{{ error }}</span>
    </base-label>

    <v-select
      v-bind="$attrs"
      size="sm"
      :value="selectedAppointmentType"
      :options="appointmentTypes"
      placeholder="Select area for discussion"
      :reduce="at => at.id"
      label="name"
      value-attr="id"
      :searchable="true"
      :disabled="uiState !== 'idle'"
      :clearable="false"
      @input="storeAppointmentType($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: {
    showValidationErrors: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    countryId: {
      type: Number,
      required: true,
    },
    serviceId: {
      type: Number,
      required: true,
    },
    animalTypeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      uiState: 'idle',
    };
  },

  computed: {
    ...mapState(['locale']),
    ...mapGetters('appointment', {
      appointmentTypes: 'getAppointmentTypes',
      appointmentType: 'getAppointmentType',
      isPreScreeningMeeting: 'getIsPreScreeningMeeting',
    }),
    selectedAppointmentType() {
      if (!this.appointmentTypes.length) {
        return null;
      }
      if (!this.appointmentType) {
        return null;
      }
      return this.appointmentType;
    },
  },

  async mounted() {
    this.uiState = 'fetching';

    await this.fetchAppointmentTypes({
      animal_type: this.animalTypeId,
      service_id: this.serviceId,
      locale: this.locale,
    });
    this.uiState = 'idle';
  },

  methods: {
    ...mapActions('appointment', [
      'updateAppointment',
      'fetchAppointmentTypes',
    ]),
    ...mapMutations('appointment', ['setAppointmentType']),
    async storeAppointmentType(appointmentTypeId) {
      const payload = {
        appointment_type: appointmentTypeId,
      };
      this.uiState = 'updating';
      await this.updateAppointment(payload);
      const appointmentTypeObject = this.appointmentTypes.find(
        at => at.id === appointmentTypeId
      );
      this.uiState = 'idle';
      this.setAppointmentType(appointmentTypeObject);
    },
  },

  $_veeValidate: {
    value() {
      return this.selectedAppointmentType;
    },
  },
};
</script>
