<template>
  <div class="flex flex-col w-full">
    <div
      v-if="showFilters"
      id="templateFilters"
      class="w-full py-2 flex flex-col space-y-2 text-sm"
    >
      <div class="flex justify-between items-center space-x-4">
        <div class="flex-1">
          <v-select
            id="language"
            v-model="filters.languageId"
            :searchable="false"
            :clearable="false"
            placeholder="Select language"
            :reduce="language => language.id"
            label="name"
            name="language"
            :options="languages"
            @input="fetch"
          />
        </div>
        <div class="flex-1">
          <v-select
            id="templateAppointmentType"
            v-model="filters.appointmentTypeId"
            placeholder="Select case type"
            label="name"
            :reduce="type => type.id"
            name="templateAppointmentType"
            :options="appointmentTypes"
            @input="fetch"
          />
        </div>

        <button
          v-if="showFilters"
          v-tooltip="{content: 'Hide filters'}"
          class="focus:outline-none focus:ring px-1"
          @click="showFilters = false"
        >
          <fv-icon
            class="text-gray-600"
            icon="close"
          />
        </button>
      </div>
    </div>

    <div class="flex flex-col space-y-2">
      <div class="flex space-x-2 text-sm">
        <v-select
          v-model="templateId"
          :reset-on-options-change="true"
          :placeholder="templateInputPlaceholder"
          class="w-full"
          label="title"
          :reduce="template => template.id"
          :options="templates"
          :loading="loading"
          :disabled="!templates.length"
        >
          <div slot="no-options">
            Couldn't find any templates matching the given filters.
          </div>
        </v-select>

        <button
          v-if="!showFilters"
          v-tooltip="{content: 'Show template filters'}"
          class="focus:outline-none focus:ring px-1"
          @click="showFilters = !showFilters"
        >
          <fv-icon
            class="text-gray-600"
            :icon="showFilters ? 'chevron-up': 'filter'"
          />
        </button>
      </div>

      <div
        v-if="selectedTemplate"
        class="w-full flex justify-between"
      >
        <div
          v-if="!!journalContent"
          class="inline-flex space-x-2"
        >
          <base-button
            v-tooltip="{content: 'Append the template to the end of current text'}"
            size="sm"
            @click="appendTemplateToTreatment"
          >
            Append to text
          </base-button>
          <base-button
            v-tooltip="{content: 'Replace the current text with the template.'}"
            size="sm"
            @click="replaceTextWithTemplate"
          >
            <span>Replace text</span>
          </base-button>
        </div>

        <div
          v-if="!journalContent"
          class="inline-flex space-x-2"
        >
          <base-button
            @click="replaceTextWithTemplate"
          >
            Use
          </base-button>
        </div>

        <base-button
          v-if="selectedTemplate"
          size="sm"
          @click="viewTemplate = true"
        >
          <font-awesome-icon icon="eye" />
        </base-button>
      </div>
    </div>

    <base-modal
      :show="viewTemplate"
      @close="viewTemplate = false"
    >
      <div
        v-if="selectedTemplate"
        class=""
      >
        <div class="prose">
          <p class="font-black text-xl">
            {{ selectedTemplate.title }}
          </p>
          <div
            
            v-html="selectedTemplate.body"
          />
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { JournalTemplatesApi } from '@/api';

export default {
  props: {
    animalTypeId: {
      type: Number,
      required: true,
    },
    journalContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      templateId: null,
      showFilters: false,
      loading: false,
      templates: [],

      viewTemplate: false,

      filters: {
        appointmentTypeId: '',
        languageId: '',
        animalTypeId: this.$props.animalTypeId,
      },
    };
  },
  computed: {
    ...mapState('country', ['languages']),
    ...mapGetters('appointment', {
      appointmentTypes: 'getAppointmentTypes',
      language: 'getPresumedLanguage',
    }),

    selectedTemplate() {
      return this.templates.find(t => t.id === this.templateId);
    },

    templateInputPlaceholder() {
      if (this.loading) {
        return 'Looking for templates ...';
      }
      if (!this.templates.length) {
        return `There are no available templates`;
      }
      return `${this.templates.length} available templates`;
    },
  },
  async mounted() {
    if (!this.languages.length) {
      await this.fetchLanguages();
    }
    this.setInitialLanguage();
    this.fetch();
  },
  methods: {
    ...mapActions('country', ['fetchLanguages']),
    async fetch() {
      try {
        this.loading = true;
        const { data } = await JournalTemplatesApi.getAllJournalTemplates(
          this.filters
        );
        if (data.journal_templates) {
          this.templates = data.journal_templates;
        } else {
          this.templates = [];
        }
        this.loading = false;
      } catch (error) {
        this.templates = [];
        this.loading = false;
        throw Error(error.message);
      }
    },
    appendTemplateToTreatment() {
      this.$emit('appendSelected', this.selectedTemplate.body);
      this.templateId = null;
    },
    replaceTextWithTemplate() {
      this.$emit('replaceSelected', this.selectedTemplate.body);
      this.templateId = null;
    },
    setInitialLanguage() {
      if (this.language) {
        this.filters.languageId = this.language.id;
      }
    },
  },
};
</script>
