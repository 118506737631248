var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full overflow-hidden", attrs: { id: "panelArea" } },
    [
      _c(
        "div",
        {
          staticClass:
            "flex w-full items-center justify-between border-b bg-beigeLighter px-5 py-3 pt-5",
          class: { "opacity-50": _vm.editComment },
        },
        [
          _c(
            "div",
            { staticClass: "flex w-full items-center justify-between gap-x-1" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-display text-lg font-semibold tracking-wide",
                },
                [_vm._v(" " + _vm._s(_vm.tab.title) + " ")]
              ),
              _vm.showQueueOptions
                ? _c("unassign-button", {
                    attrs: { "appointment-id": _vm.appointment.id },
                  })
                : _vm._e(),
              !_vm.hideBookingButton
                ? _c(
                    "base-button",
                    {
                      attrs: { color: "light" },
                      on: {
                        click: function ($event) {
                          return (_vm.showBookingModal = true)
                        },
                      },
                    },
                    [_vm._v("Book appointment ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "inline-flex w-20 text-sm" }, [
                _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _vm.journalIsLoading
                      ? _c("base-spinner", {
                          attrs: { loading: _vm.journalIsLoading },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "flex h-full flex-col bg-white bg-opacity-75 lg:flex-row",
        },
        [
          _c(
            "div",
            { staticClass: "flex lg:order-last", attrs: { id: "panelNav" } },
            [
              _c("panel-navbar", {
                staticClass: "bg-beigeLighter",
                on: {
                  "select-tab": function ($event) {
                    return _vm.setTab($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "break-word relative w-full overflow-auto",
              attrs: { id: "tabComponent" },
            },
            [
              _c(
                "div",
                { staticClass: "px-5 pt-4 pb-6" },
                [
                  _vm.tab.tab
                    ? _c(_vm.tab.tab, {
                        tag: "component",
                        attrs: { "show-assign-button": _vm.showAssignButton },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.showBookingModal
        ? _c("RemoteBookingController", {
            attrs: {
              animal: _vm.appointment.animal,
              customer: _vm.appointment.user,
              "previous-appointment-id": _vm.appointment.id,
            },
            on: {
              close: function ($event) {
                _vm.showBookingModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }