<template>
  <div id="panelArea" class="h-full overflow-hidden">
    <div
      class="flex w-full items-center justify-between border-b bg-beigeLighter px-5 py-3 pt-5"
      :class="{ 'opacity-50': editComment }"
    >
      <div class="flex w-full items-center justify-between gap-x-1">
        <p class="font-display text-lg font-semibold tracking-wide">
          {{ tab.title }}
        </p>
        <unassign-button
          v-if="showQueueOptions"
          :appointment-id="appointment.id"
        />
        <base-button
          v-if="!hideBookingButton"
          color="light"
          @click="$event => (showBookingModal = true)"
          >Book appointment
        </base-button>
        <div class="inline-flex w-20 text-sm">
          <div class="mx-auto">
            <base-spinner v-if="journalIsLoading" :loading="journalIsLoading" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex h-full flex-col bg-white bg-opacity-75 lg:flex-row">
      <div id="panelNav" class="flex lg:order-last">
        <panel-navbar class="bg-beigeLighter" @select-tab="setTab($event)" />
      </div>
      <div id="tabComponent" class="break-word relative w-full overflow-auto">
        <div class="px-5 pt-4 pb-6">
          <component
            :is="tab.tab"
            v-if="tab.tab"
            :show-assign-button="showAssignButton"
          />
        </div>
      </div>
    </div>
    <RemoteBookingController
      v-if="showBookingModal"
      :animal="appointment.animal"
      :customer="appointment.user"
      :previous-appointment-id="appointment.id"
      @close="showBookingModal = false"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import PanelNavbar from '@/components/interface/PanelNavbar';

import InfoTab from '@/components/panelarea/InfoTab';
import JournalTab from '@/components/panelarea/JournalTab';
import AdminTab from '@/components/panelarea/AdminTab';
import PrescriptionTab from '@/components/panelarea/PrescriptionTab';
import Prescription from '@/components/journal/Prescription';

import PreviousRecordsTab from '@/components/panelarea/PreviousRecordsTab';
import AnimalTab from '@/components/panelarea/AnimalTab';
import ReferralTab from '@/components/panelarea/ReferralTab';
import AppointmentUploadsTab from '@/components/panelarea/AppointmentUploadsTab';
import RemoteBookingController from '@/components/remote-booking/RemoteBookingController.vue';
import UnassignButton from '@/components/waiting-list/UnassignButton.vue';

export default {
  components: {
    PanelNavbar,
    InfoTab,
    JournalTab,
    PrescriptionTab,
    Prescription,
    PreviousRecordsTab,
    AnimalTab,
    ReferralTab,
    AppointmentUploadsTab,
    AdminTab,
    RemoteBookingController,
    UnassignButton,
  },
  props: {
    hideBookingButton: {
      type: Boolean,
      default: false,
    },
    showAssignButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showBookingModal: false,
    };
  },
  computed: {
    ...mapState(['tab']),
    ...mapState('journal', { journalIsLoading: 'loading' }),
    ...mapGetters({
      tab: 'getTab',
    }),
    ...mapGetters('journal', { editComment: 'getEditComment' }),
    ...mapState('appointment', ['appointment']),
    ...mapState('user', ['user']),
    showQueueOptions() {
      return !!this.appointment.appointment_queue?.assigned_to;
    },
  },

  beforeMount() {
    this.setInitialTab();
  },

  methods: {
    ...mapMutations(['setTab', 'setInitialTab']),
  },
};
</script>
