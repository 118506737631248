<template>
  <div>
    <div class="leading-normal">
      <div class="flex flex-col">
        <booking-information
          :appointment="appointment"
          :booking-meta-data="bookingMetaData"
          :images="appointment.images"
          :animal="animal"
          :is-editing-enabled="true"
          class="mb-10"
        />
        <div v-if="showAssignButton && appointment.id" class="flex flex-col">
          <assign-button :appointment-id="appointment.id"></assign-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BookingInformation from '@/components/booking/BookingInformation';
import AssignButton from '@/components/waiting-list/AssignButton.vue';

export default {
  components: {
    AssignButton,
    BookingInformation,
  },
  props: {
    showAssignButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
    }),
    ...mapGetters('animal', {
      animal: 'getAnimal',
      animalGender: 'getGenderName',
    }),
    ...mapState('appointment', ['bookingMetaData']),
  },
};
</script>
