import { render, staticRenderFns } from "./FinishJournal.vue?vue&type=template&id=5b158768&scoped=true&"
import script from "./FinishJournal.vue?vue&type=script&lang=js&"
export * from "./FinishJournal.vue?vue&type=script&lang=js&"
import style0 from "./FinishJournal.vue?vue&type=style&index=0&id=5b158768&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b158768",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b158768')) {
      api.createRecord('5b158768', component.options)
    } else {
      api.reload('5b158768', component.options)
    }
    module.hot.accept("./FinishJournal.vue?vue&type=template&id=5b158768&scoped=true&", function () {
      api.rerender('5b158768', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/journal/FinishJournal.vue"
export default component.exports