<template>
  <div
    id="animalTab"
    class="h-full"
  >
    <template v-if="animal">
      <component
        :is="edit ? 'animal-edit' : 'animal-info'"
        :companies="companies"
        :animal="animal"
        @submit="updateAnimal"
        @cancelEdit="edit = false"
        @edit="edit = true"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AnimalEdit from '@/components/animal/AnimalEdit';
import AnimalInfo from '@/components/animal/AnimalInfo';


export default {
  components: {
    AnimalEdit,
    AnimalInfo,
  },

  data() {
    return {
      edit: false,
    };
  },
  computed: {
    ...mapGetters('insurance', {
      companies: 'getCompanies',
    }),
    ...mapGetters('animal', {
      animal: 'getAnimal',
      loading: 'getLoading',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
  mounted() {
    if (!this.companies.length) this.fetchCompanies(this.animal.user_id);
  },
  methods: {
    ...mapActions('insurance', ['fetchCompanies']),

    ...mapActions('animal', ['postAnimalData']),

    updateAnimal({ animal, done: callback }) {
      this.postAnimalData(animal)
        .then(res => {
          callback(true, res);
        })
        .catch(res => callback(false, res));
    },
  },
};
</script>
