<template>
  <div
    class="w-full flex flex-col space-y-2"
  >
    <base-label for="referralType">
      Emergency level <span class="text-gray-700">*</span>

      <span
        v-if="showValidationErrors && error"
        class="text-red-800 p-2 ml-8 font-semibold text-center"
      >{{ error }}</span>
    </base-label>

    <v-select
      append-to-body
      v-bind="$attrs"
      label="name"
      :value="referralType"
      :options="referralTypes"
      placeholder="Select emergency level"
      :reduce="rt => rt.id"
      :loading="uiState === 'fetching'"
      :clearable="false"
      @input="updateReferralType($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    referralId: {
      type: Number || null,
      default: null,
    },
    showValidationErrors: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uiState: 'idle',
    };
  },
  computed: {
    ...mapGetters('journal', {
      referralTypes: 'getReferralTypes',
    }),
    referralType() {
      if (!this.referralTypes.length) {
        return null;
      }
      return this.referralTypes.find(rt => this.referralId === rt.id);
    },
  },
  async mounted() {
    this.uiState = 'fetching';
    this.fetchReferralTypes();
    this.uiState = 'idle';
  },
  methods: {
    ...mapMutations('journal', ['setReferralType']),
    ...mapActions('journal', ['fetchReferralTypes']),
    updateReferralType(referralTypeId) {
      this.setReferralType(referralTypeId);
      this.$emit('action', referralTypeId);
    },
  },

  $_veeValidate: {
    value() {
      return this.referralId;
    },
  },
};
</script>
