<template>
  <div v-if="animal">
    <desc-list>
      <desc-item>
        <desc-title>
          Name
        </desc-title>
        <desc-data>
          {{ animal.name }}
        </desc-data>
      </desc-item>
      <desc-item v-if="animal.animal_type">
        <desc-title>
          Species
        </desc-title>
        <desc-data>
          {{ animal.animal_type.name }}, {{ animal.race }}
        </desc-data>
      </desc-item>
      <desc-item v-if="animalBirthdate">
        <desc-title>
          Age & Gender
        </desc-title>
        <desc-data>
          {{ animalGender }}, {{ animalAge }} old, ({{ animalBirthdate }})
        </desc-data>
      </desc-item>

      <desc-item v-if="animal.insurance_type">
        <desc-title>
          Insurance
        </desc-title>
        <desc-data>
          <span id="insurance">
            {{ animal.insurance_type.name }}
          </span>
          <span>{{ animal.insurance }}</span>
        </desc-data>
      </desc-item>

      <desc-item>
        <desc-title>
          Provided information
        </desc-title>
        <desc-data>
          <span v-if="animal.description">{{ animal.description }}</span>
          <span v-if="!animal.description">--</span>
        </desc-data>
      </desc-item>
    </desc-list>

    <base-button
      class="flex-1 flex items-center w-auto mt-8"
      @click="$emit('edit')"
    >
      <svg
        class="flex-shrink-0 h-5 w-5 text-gray-600"
        fill="currentColor"
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg"
      ><path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 
0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      /></svg>

      <span class="ml-2 flex-1">
        Edit information
      </span>
    </base-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import '@/utils/helpers/time-helpers';

export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('animal', {
      animal: 'getAnimal',
      animalGender: 'getGenderName',
      loading: 'getLoading',
    }),

    animalBirthdate() {
      return this.animal.birthdate;
    },
    animalAge() {
      if (!this.animal.birthdate) {
        return '';
      }
      return this.$getAge(this.animal.birthdate);
    },
  },
};
</script>
