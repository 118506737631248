<template>
  <!-- Follow up -->
  <div class="flex w-full flex-col space-y-6">
    <base-label for="treatment">
      Book a follow up
      <span class="text-gray-700">*</span>
    </base-label>

    <div class="my-2 flex max-h-full items-center">
      <label
        class="mr-2 text-sm"
        :class="{ 'text-gray-500': followUp && followUp.shouldFollowUp }"
        for="previous-journals"
      >
        No
      </label>
      <div class="form-switch max-h-full">
        <input
          id="previous-journals"
          v-model="followUp.shouldFollowUp"
          type="checkbox"
          name="sign"
          class="form-switch-checkbox"
          @change="$emit('action')"
        />
        <label for="previous-journals" class="form-switch-label display-none" />
      </div>
      <label
        class="text-sm"
        for="previous-journals"
        :class="{ 'text-gray-500': !followUp.shouldFollowUp }"
      >
        Yes
      </label>
    </div>
    <div v-if="followUp.shouldFollowUp">
      <div>
        <label
          class="mb-1 inline-block select-none text-base font-semibold text-gray-900"
          >With</label
        >
        <v-select
          v-model="selectedOption"
          append-to-body
          label="name"
          :value="selectedOption"
          :options="followUpWithOptions"
          placeholder="Select follow up type"
          @input="$emit('action')"
        />
      </div>
      <div class="mt-7">
        <label
          class="mb-1 inline-block select-none text-base font-semibold text-gray-900"
          for="followUpAt"
          >No later than</label
        >
        <datepicker
          id="followUpAt"
          v-model="followUp.followUpAt"
          format="yyyy-MM-dd"
          :value="followUp.followUpAt"
          input-class="flex flex-1 font-semibold bg-white
          border rounded cursor-pointer focus:outline-none py-0 h-10"
          wrapper-class=""
          calendar-class="font-normal rounded shadow uppercase text-sm left-auto"
          :monday-first="true"
          minimum-view="day"
          maximum-view="month"
          placeholder="Select a day"
          @input="$emit('action')"
        />
      </div>
      <div class="mt-5">
        <label
          class="mb-2 flex items-center rounded bg-blue-700 px-2 py-3 text-sm font-bold text-white"
          role="alert"
        >
          <fv-icon icon="Information" />
          <p class="ml-1">
            Instructions and comments for internal use only. Not shown in
            journal.
          </p>
        </label>
        <label
          class="mb-1 inline-block select-none text-base text-gray-900"
          for="followAtComment"
        ></label>
        <textarea-input
          id="followAtComment"
          ref="followAtComment"
          v-model="followUp.comment"
          :rows="4"
          placeholder="Internal comment for 2288"
          name="followAtComment"
          @input="$emit('action')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { journalFollowUpOptions } from '@/config/journal-config';

export default {
  components: {
    Datepicker,
  },
  props: {
    followUp: {
      type: Object || null,
      default: null,
    },
  },
  data() {
    return {
      uiState: 'idle',
      followUpWithOptions: journalFollowUpOptions,
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.followUpWithOptions.find(
          option => option.id == this.followUp.followUpWith
        );
      },
      set(option) {
        this.followUp.followUpWith = option.id;
        return option.id;
      },
    },
  },
  async mounted() {},
  methods: {},
};
</script>
