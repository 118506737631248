<template>
  <div class="flex h-full flex-col pb-20">
    <div class="flex items-center justify-between space-x-2">
      <div class="mb-4 inline-flex items-center">
        <p
          v-if="referralTypes.length"
          v-tooltip="'You can change this property under the journal tab'"
          class="select-none underline"
        >
          {{ referralTypeString }}
        </p>
        <base-spinner
          v-if="!referralTypes.length"
          :loading="!referralTypes.length"
        />
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ reset }">
      <form
        v-loading="loading"
        class="mt-2"
        @submit.prevent="submit"
        @reset.prevent="
          reset();
          clearForm(true);
        "
      >
        <div v-if="showClinicMap" class="mb-6 flex flex-col space-y-2">
          <base-label v-if="showClinicSelect" for="clinic"> Clinic </base-label>
          <clinic-select
            :clinic="referral.clinic"
            :show-select="showClinicSelect"
            :should-reset="resetClinicData"
            @clinic-selected="selectClinic"
            @clinic-deselected="clearSelectedClinic"
          />

          <ClinicReferralBox
            v-if="referral.clinic || clinicIsLoading"
            :clinic="referral.clinic"
            @clinic-deselected="clearSelectedClinic"
          />
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          rules="required|email"
          name="email"
        >
          <div class="mb-6 flex flex-col space-y-2">
            <div
              v-if="referral.clinic"
              class="mb-2 flex justify-between rounded-lg border bg-fv-green bg-opacity-5 py-1 px-2 font-semibold text-fv-green"
            >
              <div class="inline-flex w-4/5 items-center space-x-2">
                <fv-icon icon="clinics" />
                <span
                  >The email will be sent to {{ referral.clinic.name }}.</span
                >
              </div>

              <div class="inline-flex flex-1 justify-end">
                <text-button @click="clearSelectedClinic"> Cancel </text-button>
              </div>
            </div>

            <base-label for="email">
              Email
              <transition name="fade">
                <span
                  v-if="errors.length"
                  class="ml-8 mb-2 p-2 text-center font-semibold text-red-700"
                  >{{ errors[0] }}</span
                >
              </transition>
            </base-label>
            <base-input
              id="email"
              ref="email"
              v-model="referral.email"
              v-focus
              type="email"
              placeholder="Clinic's email"
              name="email"
            />
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          rules="required|min:8"
          name="reason"
        >
          <div class="mb-6 flex flex-col space-y-2">
            <base-label for="reason">
              Reason for clinic visit / clinical diagnosis
              <transition name="fade">
                <span
                  v-if="errors.length"
                  class="ml-8 mb-2 p-2 text-center font-semibold text-red-700"
                  >{{ errors[0] }}</span
                >
              </transition>
            </base-label>
            <textarea-input
              id="reason"
              ref="reason"
              v-model="referral.reason"
              :rows="8"
              placeholder="Type here"
              name="reason"
            />
          </div>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required" name="contact">
          <div class="mb-6 flex flex-col space-y-2">
            <base-label for="contact">
              Further contact

              <transition name="fade">
                <span
                  v-if="errors.length"
                  class="ml-8 mb-2 p-2 text-center font-semibold text-red-700"
                  >{{ errors[0] }}</span
                >
              </transition>
            </base-label>
            <v-select
              id="contact"
              ref="contact"
              label="name"
              :reduce="opt => opt.id"
              :loading="!contactOptions.length"
              :options="contactOptions"
              placeholder="Select an option"
              name="contact"
              :value="referral.referralContactId"
              @input="referral.referralContactId = $event"
            />
          </div>
        </ValidationProvider>

        <div class="mb-6 flex flex-col space-y-2">
          <base-label> Send previous journals? </base-label>
          <div class="my-2 flex max-h-full items-center">
            <label
              class="mr-2 text-sm"
              :class="{ 'text-gray-500': referral.sendAllJournals }"
              for="previous-journals"
            >
              No
            </label>
            <div class="form-switch max-h-full">
              <input
                id="previous-journals"
                v-model="referral.sendAllJournals"
                type="checkbox"
                name="sign"
                class="form-switch-checkbox"
              />
              <label
                for="previous-journals"
                class="form-switch-label display-none"
              />
            </div>
            <label
              class="text-sm"
              for="previous-journals"
              :class="{ 'text-gray-500': !referral.sendAllJournals }"
            >
              Yes
            </label>
          </div>
        </div>

        <div class="mb-6 flex flex-col space-y-2">
          <base-label> Send additions and comments? </base-label>
          <switch-input
            :key="countKey"
            :initial-value="sendComments"
            title="sendComments"
            @input="setSendComments($event)"
          />
          <div v-if="sendComments">
            <base-label> Select additions and comments to send </base-label>
            <div v-for="appt in sortedAppointments" :key="appt.id" class="pt-2">
              <p
                v-if="appt.id !== appointment.id"
                class="mb-2 flex items-center space-x-3 text-base"
              >
                <fv-icon :icon="getServiceChannel(appointment.booking)" />
                <span>{{ $formatDate(appt.booking.booking_datetime) }}</span>
                <span class="text-left"
                  >{{ title(appt) }} -
                  {{ vetName(appt.booking) }}
                </span>
              </p>
              <p v-else class="mb-2 space-x-3 text-base">Current journal</p>
              <journal-comment
                v-for="comment in appt.journal.comments"
                :key="comment.id"
                :comment="comment"
                class="my-2 block w-full cursor-pointer rounded border bg-white px-3 shadow-sm"
                :class="{
                  'border-primary': referral.selectedComments.includes(
                    comment.id
                  ),
                }"
                :select-mode="true"
                @click.native="selectComment(comment.id)"
              >
                <div slot="icon">
                  <fv-icon
                    :icon="
                      referral.selectedComments.includes(comment.id)
                        ? 'check-mark-alt'
                        : 'add'
                    "
                    :class="
                      referral.selectedComments.includes(comment.id)
                        ? 'text-primary'
                        : 'text-gray-500'
                    "
                  />
                </div>
              </journal-comment>
            </div>
          </div>
        </div>
        <div v-if="images.length" class="mt-4 w-full">
          <base-label> Images: </base-label>
          <image-gallery
            :key="countKey"
            :images="images"
            :appointment="appointment"
            :gallery="false"
            :initial-images="referral.selectedImages"
            @selected-images="handleImages"
          />
        </div>
        <div class="mt-2 flex w-full justify-end pb-6">
          <base-button color="cancel" class="mr-2" type="reset">
            Cancel
          </base-button>
          <base-button color="dark" type="submit" :loading="loading">
            Send
          </base-button>
        </div>
      </form>
    </ValidationObserver>

    <div
      v-if="appointment.referrals && appointment.referrals.length"
      class="py-2"
    >
      <text-button class="mb-4" @click="showReferralList = !showReferralList">
        <div class="inline-flex items-center space-x-2">
          <p>Previously sent</p>
          <fv-icon :icon="showReferralList ? 'chevron-up' : 'chevron-down'" />
        </div>
      </text-button>
      <referral-summary
        v-show="showReferralList"
        :referrals="appointment.referrals"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { SV_COUNTRY_ID, UK_COUNTRY_ID } from '@/config/countries';
import { cloneDeep } from 'lodash';
import ImageGallery from '@/components/images/ImageGallery';
import ClinicSelect from '@/components/referral/ClinicSelect';
import ClinicReferralBox from '@/components/referral/ClinicReferralBox';
import SwitchInput from '@/UI/SwitchInput.vue';
import Comment from '@/components/journal/Comment';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import ReferralSummary from '../appointments/ReferralSummary.vue';

export default {
  components: {
    ReferralSummary,
    ImageGallery,
    ClinicSelect,
    ClinicReferralBox,
    SwitchInput,
    'journal-comment': Comment,
  },
  data() {
    return {
      loading: false,
      showReferralList: true,
      resetClinicData: false,
      countKey: 0,
      referral: {},
      sendComments: false,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('user', ['country']),
    ...mapState('clinicMap', ['clinicIsLoading']),
    ...mapState('referral', ['allJournalComments']),
    ...mapGetters('referral', {
      storeReferral: 'getReferral',
      contactOptions: 'getContactOptions',
    }),

    ...mapGetters('journal', {
      journal: 'getJournal',
      referralTypes: 'getReferralTypes',
    }),

    ...mapGetters('appointment', {
      appointment: 'getAppointment',
      images: 'getImages',
    }),

    countryId() {
      return this.country.id;
    },

    showClinicSelect() {
      return (
        this.countryId === SV_COUNTRY_ID || this.countryId === UK_COUNTRY_ID
      );
    },

    showClinicMap() {
      return (
        this.countryId === SV_COUNTRY_ID || this.countryId === UK_COUNTRY_ID
      );
    },

    urgencyLevelIsSet() {
      return !!(this.journal.referral_id && this.referralTypes.length);
    },

    referralTypeString() {
      let result = { name: 'Urgency level not set' };
      if (this.urgencyLevelIsSet) {
        result = this.referralTypes.find(
          type => type.id === this.journal.referral_id
        );
      }
      return result.name;
    },

    sortedAppointments() {
      // Sets current meeting first in the array
      const index = this.allJournalComments.findIndex(
        appointment => appointment.journal.id === this.journal.id
      );

      if (index !== -1) {
        if (!this.referral.sendAllJournals)
          return [this.allJournalComments[index]];

        const updatedData = [...this.allJournalComments];
        updatedData.unshift(...updatedData.splice(index, 1));
        return updatedData;
      }
      return this.allJournalComments;
    },
  },

  created() {
    this.referral = cloneDeep(this.storeReferral);
  },

  beforeDestroy() {
    this.setReferral(this.referral);
  },

  mounted() {
    if (!this.referralTypes.length) {
      this.fetchReferralTypes();
    }
    if (!this.contactOptions.length) {
      this.fetchContactOptions();
    }
    if (!this.allJournalComments.length) {
      this.fetchAllJournalComments(this.journal.animal_id);
    }

    this.sendComments = !!this.referral.selectedComments.length;
    this.countKey += 1;
  },

  methods: {
    ...mapActions('journal', ['fetchReferralTypes']),
    ...mapActions('referral', [
      'sendReferral',
      'fetchContactOptions',
      'clearReferral',
      'fetchAllJournalComments',
    ]),
    ...mapMutations('appointment', ['setAppointment']),
    ...mapMutations('referral', ['setReferral']),

    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading = true;
        const payload = {
          email: this.referral.email,
          reason: this.referral.reason,
          journal_id: this.journal.id,
          send_all_journals: this.referral.sendAllJournals,
          images: this.referral.selectedImages,
          journal_comments: this.sendComments
            ? this.referral.selectedComments
            : [],
          referral_contact_id: this.referral.referralContactId,
        };
        if (this.referral.clinic?.id && this.showClinicSelect) {
          payload.clinic_id = this.referral.clinic.id;
        }

        try {
          const { data } = await this.sendReferral(payload);
          const { appointment } = this;
          appointment.referrals.push(data.referral);
          this.setAppointment(appointment);
          const sentTo =
            this.referral.clinic?.name && this.showClinicSelect
              ? this.referral.clinic.name
              : this.referral.email;

          this.$notify({
            group: 'primary',
            title: 'Success ',
            text: `Referral sent to ${sentTo}`,
          });
          this.clearForm();
          this.loading = false;
        } catch (e) {
          console.error(e);
          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            text: e.message,
          });
          this.loading = false;
        }
      }
    },
    async clearForm(cancel = false) {
      if (!cancel) {
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
      await this.clearReferral();
      this.referral = cloneDeep(this.storeReferral);
      this.resetClinicData = true;
      this.countKey += 1;
      this.sendComments = false;
    },

    handleImages(images) {
      this.referral.selectedImages = images;
    },

    selectClinic(clinic) {
      if (!clinic || (!clinic.email && !clinic.referral_email)) {
        this.$notify({
          group: 'error',
          text: `${clinic.name} does not have an email set`,
        });
        this.clearSelectedClinic();
        return;
      }
      this.referral.clinic = clinic;
      this.referral.email = clinic.referral_email || clinic.email;
    },

    clearSelectedClinic() {
      this.resetClinicData = !this.resetClinicData;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.referral.clinic = null;
      this.referral.email = '';
    },

    vetName(booking) {
      if (booking?.veterinary) {
        return (
          booking.veterinary.display_name ||
          `${booking.veterinary.first_name} ${booking.veterinary.last_name}`
        );
      }
      return 'No vet assigned';
    },

    title(appointment) {
      if (appointment?.booking) {
        const start = new Date(appointment.booking?.booking_datetime).getTime();
        const now = new Date().getTime();
        const diff = start - now;

        if (diff > 0) {
          return `Upcoming booking (${this.formatTime(appointment.booking)})`;
        }
      }
      if (appointment?.appointment_type?.name) {
        return appointment.appointment_type.name;
      }
      if (appointment?.journal && !appointment.journal.status) {
        return 'Unfinished journal';
      }
      if (!appointment?.journal) {
        return 'Missing journal';
      }
      return '';
    },

    formatTime(booking) {
      return format(
        zonedTimeToUtc(booking.booking_datetime, booking.country.timezone),
        'HH:mm'
      );
    },

    getServiceChannel(booking) {
      return booking?.service?.key === 'veterinary_chat' ? 'chat' : 'video';
    },

    selectComment(id) {
      if (!this.referral.selectedComments.includes(id))
        this.referral.selectedComments.push(id);
      else {
        const index = this.referral.selectedComments.indexOf(id);

        if (index > -1) {
          this.referral.selectedComments.splice(index, 1);
        }
      }
    },

    setSendComments(value) {
      this.sendComments = value;
      if (!this.sendComments) this.referral.selectedComments = [];
    },
  },
};
</script>

<style scoped lang="postcss">
>>> .vs__dropdown-toggle {
  min-height: 38px;
}
</style>
