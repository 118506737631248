var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex h-full flex-col space-y-8 overflow-auto pb-12 text-sm",
      attrs: { id: "adminTab" },
    },
    [
      _c("div", { staticClass: "flex flex-col space-y-2 font-mono" }, [
        _c("p", { staticClass: "mb-2 block border-b pb-1" }, [
          _vm._v("References"),
        ]),
        _c("p", [
          _vm._v(" Status: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.status)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Appointment-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.booking.id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Booking-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.booking.id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Journal-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.journal.id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Animal-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.animal.id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Customer-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.customer_id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Vet-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.booking.user_id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" External-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.external_id)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Service-ID: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.booking.service_id)),
          ]),
        ]),
        _vm.appointment.promo
          ? _c("p", [
              _vm._v(" Promo: "),
              _c("span", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.appointment.promo)),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex flex-col space-y-2 font-mono" }, [
        _c("p", { staticClass: "mb-2 block border-b pb-1" }, [
          _vm._v("Country & timezone"),
        ]),
        _c("p", [
          _vm._v(" Country: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.booking.country.name)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Timezone: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.timezone)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex flex-col space-y-2 font-mono" }, [
        _c("p", { staticClass: "mb-2 block border-b pb-1" }, [
          _vm._v("Notifications (Database time)"),
        ]),
        _c("p", [
          _vm._v(" Reminder sent: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.reminder)),
          ]),
        ]),
        _c("p", [
          _vm._v(" SMS-status: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.sms_status)),
          ]),
        ]),
        _c("p", [
          _vm._v(" SMS sent: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.sms_reminder)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Vet online: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.vet_online)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Vet offline: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.appointment.vet_offline)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Journal sent: "),
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(_vm.journal.notified)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex flex-col space-y-2 font-mono" }, [
        _c("p", { staticClass: "mb-2 block border-b pb-1" }, [
          _vm._v("OpenTok"),
        ]),
        _c("p", [
          _vm._v(" Session-ID:"),
          _c("br"),
          _c("span", { staticClass: "text-sm font-semibold" }, [
            _vm._v(_vm._s(_vm.sessionID)),
          ]),
        ]),
        _c("p", [
          _vm._v(" Token:"),
          _c("br"),
          _c("span", { staticClass: "text-sm font-semibold" }, [
            _vm._v(_vm._s(_vm.token)),
          ]),
        ]),
      ]),
      _vm.appointment.video_archive
        ? _c("div", { staticClass: "flex flex-col space-y-2 font-mono" }, [
            _c("p", { staticClass: "mb-2 block border-b pb-1" }, [
              _vm._v("Video archive"),
            ]),
            _c("p", [
              _vm._v(" Status"),
              _c("br"),
              _c("span", { staticClass: "text-sm font-semibold" }, [
                _vm._v(_vm._s(_vm.appointment.video_archive.status)),
              ]),
            ]),
            _c("p", [
              _vm._v(" Archive-ID:"),
              _c("br"),
              _c("span", { staticClass: "text-sm font-semibold" }, [
                _vm._v(_vm._s(_vm.appointment.video_archive.archive_id)),
              ]),
            ]),
            _c("p", [
              _vm._v(" Duration:"),
              _c("br"),
              _c("span", { staticClass: "text-sm font-semibold" }, [
                _vm._v(
                  _vm._s(_vm.appointment.video_archive.duration) + " seconds"
                ),
              ]),
            ]),
            _c("p", [
              _vm._v(" Size:"),
              _c("br"),
              _c("span", { staticClass: "text-sm font-semibold" }, [
                _vm._v(_vm._s(_vm.appointment.video_archive.size) + " kb"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }