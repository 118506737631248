<template>
  <div
    id="adminTab"
    class="flex h-full flex-col space-y-8 overflow-auto pb-12 text-sm"
  >
    <div class="flex flex-col space-y-2 font-mono">
      <p class="mb-2 block border-b pb-1">References</p>
      <p>
        Status: <span class="font-semibold">{{ appointment.status }}</span>
      </p>
      <p>
        Appointment-ID:
        <span class="font-semibold">{{ appointment.booking.id }}</span>
      </p>
      <p>
        Booking-ID:
        <span class="font-semibold">{{ appointment.booking.id }}</span>
      </p>
      <p>
        Journal-ID: <span class="font-semibold">{{ journal.id }}</span>
      </p>
      <p>
        Animal-ID: <span class="font-semibold">{{ animal.id }}</span>
      </p>
      <p>
        Customer-ID:
        <span class="font-semibold">{{ appointment.customer_id }}</span>
      </p>
      <p>
        Vet-ID:
        <span class="font-semibold">{{ appointment.booking.user_id }}</span>
      </p>
      <p>
        External-ID:
        <span class="font-semibold">{{ appointment.external_id }}</span>
      </p>
      <p>
        Service-ID:
        <span class="font-semibold">{{ appointment.booking.service_id }}</span>
      </p>
      <p v-if="appointment.promo">
        Promo: <span class="font-semibold">{{ appointment.promo }}</span>
      </p>
    </div>

    <div class="flex flex-col space-y-2 font-mono">
      <p class="mb-2 block border-b pb-1">Country & timezone</p>
      <p>
        Country:
        <span class="font-semibold">{{
          appointment.booking.country.name
        }}</span>
      </p>
      <p>
        Timezone: <span class="font-semibold">{{ timezone }}</span>
      </p>
    </div>

    <div class="flex flex-col space-y-2 font-mono">
      <p class="mb-2 block border-b pb-1">Notifications (Database time)</p>
      <p>
        Reminder sent:
        <span class="font-semibold">{{ appointment.reminder }}</span>
      </p>
      <p>
        SMS-status:
        <span class="font-semibold">{{ appointment.sms_status }}</span>
      </p>
      <p>
        SMS sent:
        <span class="font-semibold">{{ appointment.sms_reminder }}</span>
      </p>
      <p>
        Vet online:
        <span class="font-semibold">{{ appointment.vet_online }}</span>
      </p>
      <p>
        Vet offline:
        <span class="font-semibold">{{ appointment.vet_offline }}</span>
      </p>
      <p>
        Journal sent: <span class="font-semibold">{{ journal.notified }}</span>
      </p>
    </div>
    <div class="flex flex-col space-y-2 font-mono">
      <p class="mb-2 block border-b pb-1">OpenTok</p>
      <p>
        Session-ID:<br />
        <span class="text-sm font-semibold">{{ sessionID }}</span>
      </p>
      <p>
        Token:<br />
        <span class="text-sm font-semibold">{{ token }}</span>
      </p>
    </div>
    <div
      v-if="appointment.video_archive"
      class="flex flex-col space-y-2 font-mono"
    >
      <p class="mb-2 block border-b pb-1">Video archive</p>
      <p>
        Status<br />
        <span class="text-sm font-semibold">{{
          appointment.video_archive.status
        }}</span>
      </p>
      <p>
        Archive-ID:<br />
        <span class="text-sm font-semibold">{{
          appointment.video_archive.archive_id
        }}</span>
      </p>
      <p>
        Duration:<br />
        <span class="text-sm font-semibold"
          >{{ appointment.video_archive.duration }} seconds</span
        >
      </p>
      <p>
        Size:<br />
        <span class="text-sm font-semibold"
          >{{ appointment.video_archive.size }} kb</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  computed: {
    ...mapState('appointment', ['appointment', 'timezone']),
    ...mapState('journal', ['journal']),
    ...mapState('animal', ['animal']),
    ...mapState('opentok', ['sessionID', 'token']),
  },
  methods: {
    parseTimezone(timestamp) {
      if (!timestamp) {
        return '-';
      }
      return format(
        zonedTimeToUtc(timestamp, this.timezone),
        'yyyy-MM-dd HH:mm:ss'
      );
    },
  },
};
</script>
