<template>
  <div class="flex flex-col space-y-8 pb-16">
    <!--  Section for the current consultation's animal -->
    <div>
      <div class="mb-2 flex justify-between items-center">
        <span class="">{{ currentAnimal.name }}</span>
        <!-- <base-button
          v-tooltip="'Close all open records'"
          size="sm"
          @click="closeAllRecords"
        >
          <font-awesome-icon icon="caret-up" />
        </base-button> -->
      </div>
      <animal-previous-records
        ref="currentAnimalRecords"
        :animal="currentAnimal"
        :open-history-on-mount="true"
        :show-tab-action-button="false"
        :current-appointment-id="appointment.id"
      />
    </div>
    <!-- End of section for the current consultation's animal -->

    <!-- Section for AO's other animals and their recors -->
    <div v-if="otherAnimals.length">
      <div class="flex flex-col space-y-2">
        <animal-previous-records
          v-for="animal in otherAnimals"
          :key="animal.id"
          :animal="animal"
        />
      </div>
    </div>
    <!-- End of section for AO's other animals and their recors -->

    <!-- Section for animals shared with AO -->
    <div v-if="sharedAnimals.length">
      <p class="font-semibold mb-4">
        Shared animals
      </p>

      <div class="flex flex-col space-y-4">
        <animal-previous-records
          v-for="animal in sharedAnimals"
          :key="animal.id"
          :animal="animal"
        />
      </div>
    </div>
    <!-- End of section for animals shared with AO -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AnimalPreviousRecords from '@/components/animal/previous-records/AnimalPreviousRecords';

export default {
  components: {
    AnimalPreviousRecords,
  },

  data() {
    return {
      loading: false,
      currentAnimalHistory: null,
    };
  },

  computed: {
    ...mapGetters('appointment', {
      appointmentId: 'getAppointmentId',
    }),
    ...mapGetters('animal', { otherAnimals: 'getAnimalsNotInConsultation' }),
    ...mapState('animal', {
      currentAnimal: 'animal',
      sharedAnimals: 'sharedAnimals',
    }),
    ...mapState('appointment', ['appointment']),
  },

  methods: {
    closeAllRecords() {
      const { currentAnimalRecords } = this.$refs;
      if (currentAnimalRecords) {
        currentAnimalRecords.closeAllRecords();
      }
    },
  },
};
</script>
