var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-button",
    { attrs: { color: "primary" }, on: { click: _vm.assignQueuedAppointment } },
    [_vm._v(" Start consultation ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }