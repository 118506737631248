<template>
  <div
    class="flex w-full"
    :class="showSelect ? 'justify-between' : ''"
  >
    <div   
      v-if="showSelect" 
      class="relative flex-1 mr-2 clinic-select"
    >
      <v-select
        id="clinicName"
        :reduce="option => option.id"
        :value="clinic"
        name="clinicName"
        class="font-medium rounded text-gray-700 w-full"
        label="name"
        placeholder="Search for a clinic or city"
        :clearable="false"
        :options="options"
        :filterable="false"
        :loading="uiState === 'loading'"
        :clear-search-on-select="false"
        @input="getClinic"
        @search="searchClinics"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          <div class="py-1">
            <span v-if="loading">Searching..</span>
            <span v-if="!loading && (search.length <= 1)">Type to search</span>
            <span v-if="(!loading) && (search.length > 1)">
              Couldn't find any matches for "{{ search }}"
            </span>
          </div>
        </template>
      </v-select>
    </div>

    <text-button
      color="dark"
      type="button"
      @click="displayClinicMap(true)"
    >
      <div class="inline-flex items-center space-x-2">
        <fv-icon
          class="text-primary"
          icon="google-maps"
        />
        <span>Find on map</span>
      </div>
    </text-button>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import { ClinicsApi } from '@/api';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    showSelect: {
      type: Boolean,
      default: true,
    },
    shouldReset: {
      type: Boolean,
      default: false,
    },
    clinic: {
      type: [null || Object],
      default: null,
    },
  },
  data() {
    return {
      options: [],
      uiState: 'idle',
    };
  },
  computed: {
    ...mapState('clinicMap', {
      clinicMapIsOpen: 'isOpen',
      selectedClinic: 'referralClinic',
    }),
  },
  watch: {
    shouldReset() {
      if (this.shouldReset) {
        this.$emit('clinic-deselected');
      }
    },
    clinicMapIsOpen(isOpen) {
      if (!isOpen) {
        const clinic = this.selectedClinic ?? this.clinic;
        this.$emit('clinic-selected', clinic);
      }
    },
  },
  methods: {
    ...mapActions('clinicMap', ['getClinicDetails']),
    ...mapMutations('clinicMap', ['displayClinicMap']),

    // eslint-disable-next-line func-names
    searchClinics: debounce(function(search) {
      if (search.length > 1) {
        this.searchMatchingClinics(search);
      } else {
        this.options = [];
      }
    }, 300),

    async searchMatchingClinics(search) {
      this.uiState = 'loading';

      const clinics = await ClinicsApi.filterClinics({ search, emailSet: 1 });

      if (clinics) {
        this.options = clinics;
      }

      this.uiState = 'idle';
      return clinics;
    },

    async getClinic(id) {
      const clinicData = await this.getClinicDetails(id);
      this.$emit('clinic-selected', clinicData);
    },
  },
};
</script>
<style scoped lang="postcss">
.clinic-select >>> .v-select .vs__dropdown-toggle {
  min-height: 38px;
}
</style>
