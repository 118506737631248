var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-full flex-col pb-20" },
    [
      _c(
        "div",
        { staticClass: "flex items-center justify-between space-x-2" },
        [
          _c(
            "div",
            { staticClass: "mb-4 inline-flex items-center" },
            [
              _vm.referralTypes.length
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            "You can change this property under the journal tab",
                          expression:
                            "'You can change this property under the journal tab'",
                        },
                      ],
                      staticClass: "select-none underline",
                    },
                    [_vm._v(" " + _vm._s(_vm.referralTypeString) + " ")]
                  )
                : _vm._e(),
              !_vm.referralTypes.length
                ? _c("base-spinner", {
                    attrs: { loading: !_vm.referralTypes.length },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var reset = ref.reset
              return [
                _c(
                  "form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "mt-2",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                      reset: function ($event) {
                        $event.preventDefault()
                        reset()
                        _vm.clearForm(true)
                      },
                    },
                  },
                  [
                    _vm.showClinicMap
                      ? _c(
                          "div",
                          { staticClass: "mb-6 flex flex-col space-y-2" },
                          [
                            _vm.showClinicSelect
                              ? _c("base-label", { attrs: { for: "clinic" } }, [
                                  _vm._v(" Clinic "),
                                ])
                              : _vm._e(),
                            _c("clinic-select", {
                              attrs: {
                                clinic: _vm.referral.clinic,
                                "show-select": _vm.showClinicSelect,
                                "should-reset": _vm.resetClinicData,
                              },
                              on: {
                                "clinic-selected": _vm.selectClinic,
                                "clinic-deselected": _vm.clearSelectedClinic,
                              },
                            }),
                            _vm.referral.clinic || _vm.clinicIsLoading
                              ? _c("ClinicReferralBox", {
                                  attrs: { clinic: _vm.referral.clinic },
                                  on: {
                                    "clinic-deselected":
                                      _vm.clearSelectedClinic,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("ValidationProvider", {
                      attrs: { rules: "required|email", name: "email" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-6 flex flex-col space-y-2",
                                  },
                                  [
                                    _vm.referral.clinic
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mb-2 flex justify-between rounded-lg border bg-fv-green bg-opacity-5 py-1 px-2 font-semibold text-fv-green",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "inline-flex w-4/5 items-center space-x-2",
                                              },
                                              [
                                                _c("fv-icon", {
                                                  attrs: { icon: "clinics" },
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "The email will be sent to " +
                                                      _vm._s(
                                                        _vm.referral.clinic.name
                                                      ) +
                                                      "."
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "inline-flex flex-1 justify-end",
                                              },
                                              [
                                                _c(
                                                  "text-button",
                                                  {
                                                    on: {
                                                      click:
                                                        _vm.clearSelectedClinic,
                                                    },
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "base-label",
                                      { attrs: { for: "email" } },
                                      [
                                        _vm._v(" Email "),
                                        _c(
                                          "transition",
                                          { attrs: { name: "fade" } },
                                          [
                                            errors.length
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-8 mb-2 p-2 text-center font-semibold text-red-700",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("base-input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                      ],
                                      ref: "email",
                                      attrs: {
                                        id: "email",
                                        type: "email",
                                        placeholder: "Clinic's email",
                                        name: "email",
                                      },
                                      model: {
                                        value: _vm.referral.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.referral, "email", $$v)
                                        },
                                        expression: "referral.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      attrs: { rules: "required|min:8", name: "reason" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-6 flex flex-col space-y-2",
                                  },
                                  [
                                    _c(
                                      "base-label",
                                      { attrs: { for: "reason" } },
                                      [
                                        _vm._v(
                                          " Reason for clinic visit / clinical diagnosis "
                                        ),
                                        _c(
                                          "transition",
                                          { attrs: { name: "fade" } },
                                          [
                                            errors.length
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-8 mb-2 p-2 text-center font-semibold text-red-700",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("textarea-input", {
                                      ref: "reason",
                                      attrs: {
                                        id: "reason",
                                        rows: 8,
                                        placeholder: "Type here",
                                        name: "reason",
                                      },
                                      model: {
                                        value: _vm.referral.reason,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.referral, "reason", $$v)
                                        },
                                        expression: "referral.reason",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      attrs: { rules: "required", name: "contact" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-6 flex flex-col space-y-2",
                                  },
                                  [
                                    _c(
                                      "base-label",
                                      { attrs: { for: "contact" } },
                                      [
                                        _vm._v(" Further contact "),
                                        _c(
                                          "transition",
                                          { attrs: { name: "fade" } },
                                          [
                                            errors.length
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-8 mb-2 p-2 text-center font-semibold text-red-700",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-select", {
                                      ref: "contact",
                                      attrs: {
                                        id: "contact",
                                        label: "name",
                                        reduce: function (opt) {
                                          return opt.id
                                        },
                                        loading: !_vm.contactOptions.length,
                                        options: _vm.contactOptions,
                                        placeholder: "Select an option",
                                        name: "contact",
                                        value: _vm.referral.referralContactId,
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.referral.referralContactId =
                                            $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c(
                      "div",
                      { staticClass: "mb-6 flex flex-col space-y-2" },
                      [
                        _c("base-label", [_vm._v(" Send previous journals? ")]),
                        _c(
                          "div",
                          { staticClass: "my-2 flex max-h-full items-center" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "mr-2 text-sm",
                                class: {
                                  "text-gray-500": _vm.referral.sendAllJournals,
                                },
                                attrs: { for: "previous-journals" },
                              },
                              [_vm._v(" No ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "form-switch max-h-full" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.referral.sendAllJournals,
                                      expression: "referral.sendAllJournals",
                                    },
                                  ],
                                  staticClass: "form-switch-checkbox",
                                  attrs: {
                                    id: "previous-journals",
                                    type: "checkbox",
                                    name: "sign",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.referral.sendAllJournals
                                    )
                                      ? _vm._i(
                                          _vm.referral.sendAllJournals,
                                          null
                                        ) > -1
                                      : _vm.referral.sendAllJournals,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.referral.sendAllJournals,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.referral,
                                              "sendAllJournals",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.referral,
                                              "sendAllJournals",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.referral,
                                          "sendAllJournals",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "form-switch-label display-none",
                                  attrs: { for: "previous-journals" },
                                }),
                              ]
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "text-sm",
                                class: {
                                  "text-gray-500":
                                    !_vm.referral.sendAllJournals,
                                },
                                attrs: { for: "previous-journals" },
                              },
                              [_vm._v(" Yes ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-6 flex flex-col space-y-2" },
                      [
                        _c("base-label", [
                          _vm._v(" Send additions and comments? "),
                        ]),
                        _c("switch-input", {
                          key: _vm.countKey,
                          attrs: {
                            "initial-value": _vm.sendComments,
                            title: "sendComments",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setSendComments($event)
                            },
                          },
                        }),
                        _vm.sendComments
                          ? _c(
                              "div",
                              [
                                _c("base-label", [
                                  _vm._v(
                                    " Select additions and comments to send "
                                  ),
                                ]),
                                _vm._l(_vm.sortedAppointments, function (appt) {
                                  return _c(
                                    "div",
                                    { key: appt.id, staticClass: "pt-2" },
                                    [
                                      appt.id !== _vm.appointment.id
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 flex items-center space-x-3 text-base",
                                            },
                                            [
                                              _c("fv-icon", {
                                                attrs: {
                                                  icon: _vm.getServiceChannel(
                                                    _vm.appointment.booking
                                                  ),
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$formatDate(
                                                      appt.booking
                                                        .booking_datetime
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.title(appt)) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.vetName(
                                                          appt.booking
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 space-x-3 text-base",
                                            },
                                            [_vm._v("Current journal")]
                                          ),
                                      _vm._l(
                                        appt.journal.comments,
                                        function (comment) {
                                          return _c(
                                            "journal-comment",
                                            {
                                              key: comment.id,
                                              staticClass:
                                                "my-2 block w-full cursor-pointer rounded border bg-white px-3 shadow-sm",
                                              class: {
                                                "border-primary":
                                                  _vm.referral.selectedComments.includes(
                                                    comment.id
                                                  ),
                                              },
                                              attrs: {
                                                comment: comment,
                                                "select-mode": true,
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.selectComment(
                                                    comment.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "icon" },
                                                  slot: "icon",
                                                },
                                                [
                                                  _c("fv-icon", {
                                                    class:
                                                      _vm.referral.selectedComments.includes(
                                                        comment.id
                                                      )
                                                        ? "text-primary"
                                                        : "text-gray-500",
                                                    attrs: {
                                                      icon: _vm.referral.selectedComments.includes(
                                                        comment.id
                                                      )
                                                        ? "check-mark-alt"
                                                        : "add",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.images.length
                      ? _c(
                          "div",
                          { staticClass: "mt-4 w-full" },
                          [
                            _c("base-label", [_vm._v(" Images: ")]),
                            _c("image-gallery", {
                              key: _vm.countKey,
                              attrs: {
                                images: _vm.images,
                                appointment: _vm.appointment,
                                gallery: false,
                                "initial-images": _vm.referral.selectedImages,
                              },
                              on: { "selected-images": _vm.handleImages },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mt-2 flex w-full justify-end pb-6" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "cancel", type: "reset" },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "base-button",
                          {
                            attrs: {
                              color: "dark",
                              type: "submit",
                              loading: _vm.loading,
                            },
                          },
                          [_vm._v(" Send ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.appointment.referrals && _vm.appointment.referrals.length
        ? _c(
            "div",
            { staticClass: "py-2" },
            [
              _c(
                "text-button",
                {
                  staticClass: "mb-4",
                  on: {
                    click: function ($event) {
                      _vm.showReferralList = !_vm.showReferralList
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "inline-flex items-center space-x-2" },
                    [
                      _c("p", [_vm._v("Previously sent")]),
                      _c("fv-icon", {
                        attrs: {
                          icon: _vm.showReferralList
                            ? "chevron-up"
                            : "chevron-down",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("referral-summary", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showReferralList,
                    expression: "showReferralList",
                  },
                ],
                attrs: { referrals: _vm.appointment.referrals },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }