var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c("appointment-upload", {
        attrs: {
          "appointment-id": _vm.appointment.id,
          uploads: _vm.appointment.uploads,
          extensions: [
            ".pdf",
            ".doc",
            ".docx",
            ".rtf",
            ".jpg",
            ".png",
            ".jpeg",
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }