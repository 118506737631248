var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full", attrs: { id: "animalTab" } },
    [
      _vm.animal
        ? [
            _c(_vm.edit ? "animal-edit" : "animal-info", {
              tag: "component",
              attrs: { companies: _vm.companies, animal: _vm.animal },
              on: {
                submit: _vm.updateAnimal,
                cancelEdit: function ($event) {
                  _vm.edit = false
                },
                edit: function ($event) {
                  _vm.edit = true
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }