var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex max-h-full items-center" }, [
    _c(
      "label",
      {
        staticClass: "mr-2 text-sm",
        class: { "text-gray-500": _vm.value },
        attrs: { for: _vm.title },
      },
      [_vm._v(" No ")]
    ),
    _c("div", { staticClass: "form-switch max-h-full" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "form-switch-checkbox",
        attrs: { id: _vm.title, type: "checkbox", name: "sign" },
        domProps: {
          checked: Array.isArray(_vm.value)
            ? _vm._i(_vm.value, null) > -1
            : _vm.value,
        },
        on: {
          input: function ($event) {
            ;(_vm.value = !_vm.value), _vm.$emit("input", _vm.value)
          },
          change: function ($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          },
        },
      }),
      _c("label", {
        staticClass: "form-switch-label display-none",
        attrs: { for: _vm.title },
      }),
    ]),
    _c(
      "label",
      {
        staticClass: "text-sm",
        class: { "text-gray-500": !_vm.value },
        attrs: { for: _vm.title },
      },
      [_vm._v(" Yes ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }