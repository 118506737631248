<template>
  <div id="journalTab" class="pb-12">
    <!-- Finished journal and journal preview -->
    <Journal
      v-if="storeJournal && storeJournal.status"
      :journal="storeJournal"
      :appointment-type="appointment.appointment_type"
      :user-name="appointment.booking.veterinary.display_name"
      :user-avatar="appointment.booking.veterinary.avatar || ''"
      :timezone="appointment.booking.country.timezone"
      :filtered-additional-info="radioFields"
      :product-recommendations="
        showProductRecommendations ? productRecommendations : null
      "
    />

    <!-- Journal form (unsigned journal) -->
    <ValidationObserver
      v-if="storeJournal && !storeJournal.status"
      ref="observer"
      class="relative flex flex-wrap space-y-8"
      @submit.prevent=""
    >
      <!-- Appointment type select dropdown -->
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="appointmentType"
        class="w-full"
      >
        <AppointmentTypeSelect
          v-if="appointment.animal"
          v-model="appointment.appointment_type"
          name="appointmentType"
          :error="errors[0]"
          :show-validation-errors="showValidationErrors"
          :country-id="appointment.booking.country_id"
          :animal-type-id="appointment.animal.type_id"
          :service-id="appointment.booking.service.id"
        />
      </ValidationProvider>

      <!-- Mapped journal fields -->
      <div
        v-for="textField in textFields"
        :key="textField.id"
        class="w-full"
        :class="[textField.classList]"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :rules="
            textField.validationSettings.validate
              ? textField.validationSettings.validation
              : ''
          "
          :name="textField.fieldName"
          :vid="textField.id"
          class="flex w-full flex-col space-y-2"
        >
          <base-label :for="textField.id">
            {{ textField.label }}
            <span
              v-if="textField.validationSettings.validate"
              class="text-gray-700"
              >*</span
            >

            <transition name="fade">
              <span
                v-if="showValidationErrors && errors.length"
                class="ml-8 p-2 text-center font-semibold text-red-800"
                >{{ errors[0] }}</span
              >
            </transition>
          </base-label>
          <textarea-input
            v-if="!textField.useEditor"
            :id="textField.id"
            v-model="journal[textField.model]"
            :rows="textField.rows"
            :placeholder="textField.placeholder"
            :name="textField.fieldName"
            @input="autoSave"
          />
        </ValidationProvider>
      </div>

      <DiagnosisCodesSelect
        v-if="!isPreScreeningMeeting && isSwedishPlatform"
        v-model="journal.diagnosis_codes"
        :show-validation-errors="showValidationErrors"
        @updated="autoSave"
      />

      <!-- Journal treatment/recommendation (main content) field -->
      <ValidationProvider
        v-if="!isPreScreeningMeeting && journal"
        v-slot="{ errors }"
        rules="required|min:8"
        name="treatment"
        class="flex w-full flex-col space-y-2"
        tag="div"
      >
        <base-label for="treatment">
          Treatment / recommendation
          <span class="text-gray-700">*</span>

          <transition name="fade">
            <span
              v-if="showValidationErrors && errors.length"
              class="ml-8 p-2 text-center font-semibold text-red-800"
              >{{ errors[0] }}</span
            >
          </transition>
        </base-label>

        <TreatmentTemplateSelect
          v-if="appointment.animal"
          class="mb-4"
          :animal-type-id="appointment.animal.type_id"
          :journal-content="journal.treatment"
          @appendSelected="appendTemplateToTreatment"
          @replaceSelected="replaceContentWithTemplate"
        />

        <div class="w-full max-w-full">
          <ckeditor
            id="treatment"
            v-model="journal.treatment"
            placeholder="Treatment / recommendation"
            aria-placeholder="Treatment / recommendation"
            :editor="editor"
            :config="editorConfig"
            name="treatment"
            @input="autoSave"
          />
        </div>
      </ValidationProvider>

      <template v-if="showProductRecommendations">
        <ProductSelect
          v-if="appointment.animal"
          :appointment-type-id="
            appointment.appointment_type ? appointment.appointment_type.id : 0
          "
          :animal-type-id="appointment.animal.type_id"
          @select="addProductRecommendation"
        />

        <ProductRecommendations
          :products="productRecommendations"
          :loading="productRecommendationsLoading"
          @remove="removeProductRecommendation"
        />
      </template>

      <!-- Referral type select dropdown -->
      <ValidationProvider
        v-if="!isPreScreeningMeeting"
        v-slot="{ errors }"
        rules="required"
        name="referralType"
        class="w-full"
      >
        <ReferralTypeSelect
          v-model="journal.referral_id"
          name="referralType"
          :error="errors[0]"
          :show-validation-errors="showValidationErrors"
          :referral-id="journal.referral_id"
          @action="(journal.referral_id = $event), autoSave()"
        />
      </ValidationProvider>

      <!-- Radio buttons -->
      <div class="flex w-full flex-col space-y-6">
        <div v-for="button in radioFields" :key="button.id" class="w-full">
          <radio-select
            :title="button.title"
            :error="journal[button.model] === null && showValidationErrors"
            :model="journal[button.model]"
            :name="button.fieldName"
            :rules="button.required ? 'required' : ''"
            @action="handleRadioValue(button.model, $event)"
          />
        </div>
      </div>

      <!-- Swedish follow up -->
      <!-- Follow up for 2288 -->
      <follow-up
        v-if="countryId === svCountryId"
        :follow-up="followUp"
        @action="autoSave"
      />

      <div
        v-if="journal.appointment_data"
        class="flex w-full flex-col space-y-6"
      >
        <div
          v-for="checkbox in appointmentDataCheckbox"
          :key="checkbox.id"
          class="flex w-full items-center gap-2"
        >
          <input
            :id="checkbox.id"
            v-model="journal.appointment_data[checkbox.model]"
            type="checkbox"
            @input="autoSave"
          />
          <label :for="checkbox.id">{{ checkbox.title }}</label>
          <font-awesome-icon
            v-tooltip="{
              content: checkbox.tooltip,
            }"
            class="text-gray-600"
            icon="info-circle"
          />
        </div>
      </div>

      <div class="flex w-full flex-col space-y-6">
        <div
          v-for="checkbox in prescriptionDataCheckbox"
          :key="checkbox.id"
          class="flex w-full items-center gap-2"
        >
          <input
            :id="checkbox.id"
            v-model="journal[checkbox.model]"
            type="checkbox"
            @input="autoSave"
          />
          <label :for="checkbox.id">{{ checkbox.title }}</label>
          <font-awesome-icon
            v-tooltip="{
              content: checkbox.tooltip,
            }"
            class="text-gray-600"
            icon="info-circle"
          />
        </div>
      </div>

      <!-- Finish journal area -->
      <div
        class="flex w-full flex-col items-center space-y-4 border-t-2 bg-white bg-opacity-75 py-8"
      >
        <div>
          <div v-if="!showValidationErrors">
            <p
              v-if="journal.updated_at !== journal.created_at"
              class="inline-flex items-center space-x-2"
            >
              <span>Last saved at {{ formattedDate }}</span>
              <fv-icon class="text-fv-green" icon="check-mark" size="md" />
            </p>
          </div>

          <div v-if="showValidationErrors" class="flex items-center space-x-4">
            <p
              class="inline-flex items-center space-x-2 text-red-900"
              @click="showValidationErrors = false"
            >
              <fv-icon class="" icon="warning" />
              <span>The journal is missing required information.</span>
            </p>
          </div>
        </div>

        <div class="inline-flex w-full flex-shrink-0 justify-end space-x-2">
          <base-button
            :loading="loading"
            color="dark"
            class="w-full"
            @click="validate"
          >
            <span v-text="loading ? 'Saving' : 'Preview'" />
          </base-button>
        </div>
      </div>
    </ValidationObserver>

    <FinishJournal
      v-if="showJournalPreview"
      :show="showJournalPreview"
      :journal="journal"
      :appointment="appointment"
      :appointment-gtm="appointmentGtm"
      :product-recommendations="
        showProductRecommendations ? productRecommendations : null
      "
      @close="showJournalPreview = false"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Journal from '@/components/journal/Journal';
import FollowUp from '@/components/journal/FollowUp';
import AppointmentTypeSelect from '@/components/journal/AppointmentTypeSelect';
import ReferralTypeSelect from '@/components/journal/ReferralTypeSelect';
import TreatmentTemplateSelect from '@/components/journal/TreatmentTemplateSelect';
import DiagnosisCodesSelect from '@/components/journal/DiagnosisCodesSelect';
import FinishJournal from '@/components/journal/FinishJournal';
import ProductSelect from '@/components/journal/ProductSelect';
import ProductRecommendations from '@/components/journal/ProductRecommendations';
import '@/plugins/ckeditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SV_COUNTRY_ID } from '@/config/countries';
import { mapState, mapGetters, mapActions } from 'vuex';

import { debounce, cloneDeep } from 'lodash';
import {
  generateJournalFields,
  generateJournalRadioButtons,
  generateJournalCheckbox,
  journalFollowUpOptions,
} from '@/config/journal-config';

const AUTOSAVE_DEBOUNCE = 1000;

export default {
  components: {
    FollowUp,
    Journal,
    AppointmentTypeSelect,
    ReferralTypeSelect,
    TreatmentTemplateSelect,
    DiagnosisCodesSelect,
    FinishJournal,
    ProductSelect,
    ProductRecommendations,
  },

  data() {
    return {
      journal: {},
      showValidationErrors: false,
      showJournalPreview: false,
      needFollowUp: false,
      editor: ClassicEditor,
      svCountryId: SV_COUNTRY_ID,
      editorConfig: {
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'link',
        ],
        placeholder: 'Treatment or recommendation',
        link: {
          addTargetToExternalLinks: true,
        },
      },
      preventAutoSave: false,
      appointmentGtm: {},
      followUpWithOptions: journalFollowUpOptions,
    };
  },

  computed: {
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
      isPreScreeningMeeting: 'getIsPreScreeningMeeting',
    }),
    ...mapGetters('journal', {
      storeJournal: 'getJournal',
      followUp: 'getFollowUp',
      showProductRecommendations: 'showProductRecommendations',
      countryId: 'getCountryId',
    }),
    ...mapGetters({ tab: 'getTab' }),
    ...mapState('journal', [
      'loading',
      'productRecommendations',
      'productRecommendationsLoading',
    ]),
    ...mapState(['platform']),
    textFields() {
      return generateJournalFields({
        countryId: this.appointment.booking.country_id,
        isPreScreeningMeeting: this.isPreScreeningMeeting,
        isVeterinaryConsult: !this.isPreScreeningMeeting,
      });
    },
    radioFields() {
      return generateJournalRadioButtons({
        countryId: this.appointment.booking.country_id,
        isPreScreeningMeeting: this.isPreScreeningMeeting,
        isVeterinaryConsult: !this.isPreScreeningMeeting,
      });
    },
    checkboxFields() {
      return generateJournalCheckbox({
        countryId: this.appointment.booking.country_id,
        isPreScreeningMeeting: this.isPreScreeningMeeting,
        isVeterinaryConsult: !this.isPreScreeningMeeting,
      });
    },

    isSwedishPlatform() {
      return this.platform === 'sv';
    },
    formattedDate() {
      return format(
        zonedTimeToUtc(this.journal.updated_at, 'Europe/Stockholm'),
        'yyyy-MM-dd HH:mm'
      );
    },
    getSelectedFollowUpType() {
      return this.followUp.followUpWith;
    },
    appointmentDataCheckbox() {
      return this.checkboxFields.filter(
        checkboxItem => checkboxItem.id === 'is_follow_up'
      );
    },
    prescriptionDataCheckbox() {
      return this.checkboxFields.filter(
        checkboxItem => checkboxItem.id === 'prescription_needed'
      );
    },
  },

  created() {
    this.journal = cloneDeep(this.storeJournal);
    // Copy appointment description to journal "history" field
    if (!this.journal.anamnesis) {
      this.journal.anamnesis = this.appointment.description;
    }
  },

  mounted() {
    this.getProductRecommendations();

    if (this.appointment) {
      this.handleAppointmentData();
    }
  },

  methods: {
    ...mapActions('journal', [
      'postJournalData',
      'postFinishedJournal',
      'addProductRecommendation',
      'removeProductRecommendation',
      'getProductRecommendations',
    ]),
    async validate() {
      this.showValidationErrors = false;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          const productRecommendations = await this.getProductRecommendations();
          this.$gtm.trackEvent({
            event: 'clicked_preview_journal',
            appointment: this.appointmentGtm,
            productRecommendations: productRecommendations?.data,
          });
        } catch (error) {
          console.error('Something went wrong with the e-com request:', error);
        }

        this.saveJournal();
        this.showJournalPreview = true;
      } else {
        this.showJournalPreview = false;
        this.showValidationErrors = true;
      }
    },

    handleRadioValue(attr, bool) {
      this.journal[attr] = bool;
      this.autoSave();
    },

    handleCheckboxValue(attr, bool) {
      this.journal[attr] = bool;
      this.autoSave();
    },

    appendTemplateToTreatment(templateBody) {
      this.journal.treatment = `${this.journal.treatment} <br>---------------------<br> ${templateBody}`;
    },

    replaceContentWithTemplate(templateBody) {
      this.journal.treatment = templateBody;
    },

    handleAppointmentData() {
      const appointmentData = this.appointment.appointment_data;
      const defaultValue = appointmentData?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.key]: !!curr.value,
        };
      }, []);

      const appointmentDataFormatted =
        Object.keys(defaultValue).length > 0
          ? defaultValue
          : {
              is_follow_up: false,
              recommend_follow_up: false,
            };
      this.appointmentGtm = {
        ...this.appointment,
        appointment_data: appointmentDataFormatted,
      };

      this.journal = {
        ...this.journal,
        appointment_data: appointmentDataFormatted,
        followUp: this.followUp,
      };
    },

    async saveJournal(debounceTime = 0) {
      const { data } = await this.postJournalData(this.journal);

      this.journal.updated_at = data.journal.updated_at;

      const updatedAppointmentData = data.appointmentData.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.key]: !!curr.value,
          };
        },
        []
      );

      const formattedData = {
        ...this.appointment,
        journal: data.journal,
        appointment_data: updatedAppointmentData,
      };

      this.appointmentGtm = formattedData;

      // Watcher is triggered again since we update the stateJournal.updated_at property.
      // This will prevent a duplicate request.
      this.preventAutoSave = true;
      // Reset for rare cases where the watcher isn't triggered within bounce,
      // to make sure real changes are honored.
      setTimeout(() => {
        this.preventAutoSave = false;
      }, debounceTime);
    },

    // eslint-disable-next-line func-names
    autoSave: debounce(function () {
      if (!this.preventAutoSave) {
        this.saveJournal(AUTOSAVE_DEBOUNCE);
      }
    }, AUTOSAVE_DEBOUNCE),
  },
};
</script>
<style scoped lang="postcss">
>>> .ck-editor a {
  color: rgba(30, 64, 175, 1);
  text-decoration: underline;
}
>>> .ck-editor__editable {
  height: 42vh;
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem !important;
  padding-left: 1rem;
  color: #1a202c;
  font-size: 15px;
}
>>> .ck-editor__editable h2 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
>>> .ck-editor__editable h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
>>> .ck-editor__editable h4 {
  font-weight: 600;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

>>> .ck-editor__editable li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 28px;
}
>>> .ck-editor__editable > .ck-placeholder::before {
  color: #a1aec1;
}
>>> .ck-toolbar {
  border-radius: 0.25rem 0.25rem 0 0 !important;
  padding-left: 0.75rem;
}
>>> .ck.ck-editor__main > div > p {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
</style>
