/* eslint-disable no-undef */
<template>
  <div class="flex w-full flex-col">
    <ValidationProvider
      v-slot="{ errors }"
      rules="required"
      name="diagnosisCodes"
      tag="div"
      class="flex flex-col space-y-2"
    >
      <div class="flex w-full justify-between">
        <base-label for="diagnosisCodes">
          Diagnosis codes
          <span class="mr-1 text-gray-700">*</span>

          <transition name="fade">
            <span
              v-if="showValidationErrors && errors.length"
              class="ml-8 p-2 text-center font-semibold text-red-800"
              >{{ errors[0] }}</span
            >
          </transition>
          <font-awesome-icon
            v-tooltip="{
              content:
                'Type to search from all available diagnosis codes. The most frequently used appear on top.',
            }"
            class="text-gray-600"
            icon="info-circle"
          />
        </base-label>
        <div class="inline-flex items-center space-x-2">
          <button
            class="focus:outline-none text-sm font-semibold text-blue-600 hover:text-blue-700"
            @click="openFeedbackForm = true"
          >
            Give feedback
          </button>
        </div>
      </div>

      <div class="flex flex-col space-y-2">
        <v-select
          id="diagnosisCodes"
          :value="value"
          name="diagnosisCodes"
          class="w-full rounded text-sm font-medium text-gray-700"
          label="label"
          :multiple="true"
          :options="options"
          :clearable="true"
          :loading="uiState === 'loading'"
          @input="setCodes($event)"
          @search="searchCodes"
        />
      </div>
    </ValidationProvider>

    <base-modal v-if="openFeedbackForm" @close="openFeedbackForm = false">
      <div class="">
        <p class="border-b py-2 text-lg font-black">
          Send feedback to Pyramidion
        </p>

        <ValidationObserver
          ref="feedback"
          class="flex flex-col space-y-6 pt-6 pb-12"
          tag="form"
          @submit.prevent="submitFeedback"
        >
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="diagnosis codes"
            tag="div"
          >
            <base-label for="code"> Code </base-label>
            <v-select
              id="code"
              :reduce="code => code.id"
              :value="feedbackForm.code"
              name="diagnosisCodes"
              class="w-full rounded text-sm font-medium text-gray-700"
              label="label"
              :multiple="false"
              :options="options"
              :clearable="false"
              :loading="uiState === 'loading'"
              :map-keydown="handlers"
              @input="feedbackForm.code = $event"
              @search="searchCodes"
            />
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>

          <div>
            <base-label for="title"> Title </base-label>
            <base-input
              v-model="feedbackForm.title"
              class="text-sm"
              name="title"
            />
          </div>

          <div>
            <base-label for="message"> Message </base-label>
            <textarea-input v-model="feedbackForm.message" name="message" />
          </div>

          <base-button color="primary" :loading="uiState === 'sending'">
            Submit
          </base-button>
        </ValidationObserver>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import { DiagnosisCodesApi } from '@/api';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    showValidationErrors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      commonCodes: [],
      uiState: 'idle',
      openFeedbackForm: false,

      feedbackForm: {
        title: '',
        message: '',
        code: null,
      },
    };
  },
  computed: {
    ...mapState('journal', ['journal']),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async getCommonCodes() {
      const { data } = await DiagnosisCodesApi.getCommonCodes(20);
      this.options = data.data;
      return data.data;
    },

    async fetch(search = null) {
      this.uiState = 'loading';

      const queryParams = {
        search,
        common: 20,
      };
      const { data } = await DiagnosisCodesApi.getCodes(queryParams);
      if (data.diagnosis_codes) {
        this.options = data.diagnosis_codes;
      }

      this.uiState = 'idle';
      return data.diagnosis_codes;
    },

    // eslint-disable-next-line func-names
    searchCodes: debounce(async function (search) {
      await this.fetch(search);
    }, 500),

    async submitFeedback() {
      const valid = await this.$refs.feedback.validate();
      if (!valid) return;
      this.uiState = 'sending';
      try {
        await DiagnosisCodesApi.submitFeedback(this.feedbackForm);
        this.openFeedbackForm = false;
        this.$notify({
          group: 'primary',
          text: 'Thank you for your feedback! 🙏',
        });
      } catch (error) {
        this.uiState = 'error';
      }
      this.uiState = 'idle';
    },
    setCodes(codes) {
      this.$emit('change', codes);
      this.$emit('updated');

      setTimeout(() => {}, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
