<template>
  <div class="flex max-h-full items-center">
    <label
      class="mr-2 text-sm"
      :class="{ 'text-gray-500': value }"
      :for="title"
    >
      No
    </label>
    <div class="form-switch max-h-full">
      <input
        :id="title"
        v-model="value"
        type="checkbox"
        name="sign"
        class="form-switch-checkbox"
        @input="(value = !value), $emit('input', value)"
      />
      <label :for="title" class="form-switch-label display-none" />
    </div>
    <label class="text-sm" :for="title" :class="{ 'text-gray-500': !value }">
      Yes
    </label>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: this.initialValue,
    };
  },
};
</script>
