<template>
  <div class="flex flex-wrap">
    <div
      v-if="journal.prescription"
      class="flex flex-col space-y-6"
    >
      <p class="">
        {{ $formatDateTime(appointment.journal.notified) }}
      </p>
      <div class="flex flex-col space-y-2">
        <base-label
          for="prescription"
          text-size="xl"
        >
          Prescription
        </base-label>
        <nl2br
          tag="p"
          :text="journal.prescription"
        />
      </div>


      <div class="flex flex-col space-y-2">
        <base-label
          for="appointmentType"
          text-size="xl"
        >
          Veterinarian
        </base-label>
        <nl2br
          tag="p"
          :text="appointment.booking.veterinary.display_name"
        />
      </div>
    </div>

    <div v-else>
      <p
        class="w-full"
        :closable="false"
      >
        No prescription was ordered.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Nl2br from 'vue-nl2br';

export default {
  components: {
    Nl2br,
  },

  computed: {
    ...mapGetters('journal', {
      journal: 'getJournal',
    }),

    ...mapGetters('appointment', {
      appointment: 'getAppointment',
    }),
  },
};
</script>
