var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full space-y-2" },
    [
      _c("base-label", [_vm._v(" Product recommendations ")]),
      _c(
        "div",
        [
          _c("v-select", {
            ref: "productSelect",
            staticClass:
              "product-select font-medium rounded text-gray-700 w-full text-sm",
            attrs: {
              value: "",
              label: "name",
              options: _vm.products,
              filterable: false,
              autoscroll: false,
              loading: _vm.uiState === "loading",
              "close-on-select": false,
              "clear-search-on-blur": function () {
                return false
              },
              "clear-search-on-select": false,
              placeholder: "Search for a product",
            },
            on: { search: _vm.searchProducts },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (option) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex group flex-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.selectProduct(option)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex w-1/4 justify-items-center justify-center",
                            staticStyle: { "max-width": "100px" },
                          },
                          [
                            _c("img", {
                              staticClass: "w-full h-24 object-contain pr-2",
                              attrs: { src: option.image_url },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-col flex-1 p-2 whitespace-pre-wrap",
                          },
                          [
                            _c(
                              "strong",
                              { staticClass: "max-w-full break-normal" },
                              [_vm._v(_vm._s(option.name))]
                            ),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("span", [_vm._v(_vm._s(option.brand_name))]),
                              _vm._v(" - "),
                              _c("span", [
                                _vm._v(_vm._s(option.animal_types.join(", "))),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-2 rounded-full p-1 w-auto mr-auto text-xs",
                                class: option.is_available
                                  ? "bg-green-100 text-green-600"
                                  : "bg-red-100 text-red-600",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      option.is_available
                                        ? "In stock"
                                        : "Out of stock"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "invisible text-xs group-hover:visible hover:underline self-start",
                                attrs: { href: option.url, target: "_blank" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _vm._v(" View in store "),
                                _c("font-awesome-icon", {
                                  staticClass: "ml-1",
                                  attrs: { icon: "external-link-alt" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "no-options",
                fn: function (ref) {
                  var search = ref.search
                  var searching = ref.searching
                  return [
                    searching
                      ? [
                          _vm._v(" We couldn't find any products for "),
                          _c("em", [_vm._v(_vm._s(search))]),
                          _vm._v(". "),
                        ]
                      : _c("em", { staticStyle: { opacity: "0.5" } }, [
                          _vm._v("Start typing to search for a product."),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }