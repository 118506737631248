var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex w-full flex-col" },
    [
      _c("ValidationProvider", {
        staticClass: "flex flex-col space-y-2",
        attrs: { rules: "required", name: "diagnosisCodes", tag: "div" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "div",
                  { staticClass: "flex w-full justify-between" },
                  [
                    _c(
                      "base-label",
                      { attrs: { for: "diagnosisCodes" } },
                      [
                        _vm._v(" Diagnosis codes "),
                        _c("span", { staticClass: "mr-1 text-gray-700" }, [
                          _vm._v("*"),
                        ]),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.showValidationErrors && errors.length
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-8 p-2 text-center font-semibold text-red-800",
                                },
                                [_vm._v(_vm._s(errors[0]))]
                              )
                            : _vm._e(),
                        ]),
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content:
                                  "Type to search from all available diagnosis codes. The most frequently used appear on top.",
                              },
                              expression:
                                "{\n            content:\n              'Type to search from all available diagnosis codes. The most frequently used appear on top.',\n          }",
                            },
                          ],
                          staticClass: "text-gray-600",
                          attrs: { icon: "info-circle" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-flex items-center space-x-2" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "focus:outline-none text-sm font-semibold text-blue-600 hover:text-blue-700",
                            on: {
                              click: function ($event) {
                                _vm.openFeedbackForm = true
                              },
                            },
                          },
                          [_vm._v(" Give feedback ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-col space-y-2" },
                  [
                    _c("v-select", {
                      staticClass:
                        "w-full rounded text-sm font-medium text-gray-700",
                      attrs: {
                        id: "diagnosisCodes",
                        value: _vm.value,
                        name: "diagnosisCodes",
                        label: "label",
                        multiple: true,
                        options: _vm.options,
                        clearable: true,
                        loading: _vm.uiState === "loading",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setCodes($event)
                        },
                        search: _vm.searchCodes,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.openFeedbackForm
        ? _c(
            "base-modal",
            {
              on: {
                close: function ($event) {
                  _vm.openFeedbackForm = false
                },
              },
            },
            [
              _c(
                "div",
                {},
                [
                  _c("p", { staticClass: "border-b py-2 text-lg font-black" }, [
                    _vm._v(" Send feedback to Pyramidion "),
                  ]),
                  _c(
                    "ValidationObserver",
                    {
                      ref: "feedback",
                      staticClass: "flex flex-col space-y-6 pt-6 pb-12",
                      attrs: { tag: "form" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitFeedback.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required",
                          name: "diagnosis codes",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c("base-label", { attrs: { for: "code" } }, [
                                    _vm._v(" Code "),
                                  ]),
                                  _c("v-select", {
                                    staticClass:
                                      "w-full rounded text-sm font-medium text-gray-700",
                                    attrs: {
                                      id: "code",
                                      reduce: function (code) {
                                        return code.id
                                      },
                                      value: _vm.feedbackForm.code,
                                      name: "diagnosisCodes",
                                      label: "label",
                                      multiple: false,
                                      options: _vm.options,
                                      clearable: false,
                                      loading: _vm.uiState === "loading",
                                      "map-keydown": _vm.handlers,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.feedbackForm.code = $event
                                      },
                                      search: _vm.searchCodes,
                                    },
                                  }),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      errors.length
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-1 text-sm font-semibold text-red-800",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(errors[0]) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2471416862
                        ),
                      }),
                      _c(
                        "div",
                        [
                          _c("base-label", { attrs: { for: "title" } }, [
                            _vm._v(" Title "),
                          ]),
                          _c("base-input", {
                            staticClass: "text-sm",
                            attrs: { name: "title" },
                            model: {
                              value: _vm.feedbackForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.feedbackForm, "title", $$v)
                              },
                              expression: "feedbackForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("base-label", { attrs: { for: "message" } }, [
                            _vm._v(" Message "),
                          ]),
                          _c("textarea-input", {
                            attrs: { name: "message" },
                            model: {
                              value: _vm.feedbackForm.message,
                              callback: function ($$v) {
                                _vm.$set(_vm.feedbackForm, "message", $$v)
                              },
                              expression: "feedbackForm.message",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "base-button",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.uiState === "sending",
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }