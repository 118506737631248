var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col space-y-8 pb-16" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "mb-2 flex justify-between items-center" }, [
          _c("span", {}, [_vm._v(_vm._s(_vm.currentAnimal.name))]),
        ]),
        _c("animal-previous-records", {
          ref: "currentAnimalRecords",
          attrs: {
            animal: _vm.currentAnimal,
            "open-history-on-mount": true,
            "show-tab-action-button": false,
            "current-appointment-id": _vm.appointment.id,
          },
        }),
      ],
      1
    ),
    _vm.otherAnimals.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            _vm._l(_vm.otherAnimals, function (animal) {
              return _c("animal-previous-records", {
                key: animal.id,
                attrs: { animal: animal },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
    _vm.sharedAnimals.length
      ? _c("div", [
          _c("p", { staticClass: "font-semibold mb-4" }, [
            _vm._v(" Shared animals "),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-4" },
            _vm._l(_vm.sharedAnimals, function (animal) {
              return _c("animal-previous-records", {
                key: animal.id,
                attrs: { animal: animal },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }