<template>
  <base-button color="primary" @click="assignQueuedAppointment">
    Start consultation
  </base-button>
</template>
<script>
import { WaitingRoomApi } from '@/api';
import { mapActions } from 'vuex';

export default {
  components: {},
  props: {
    appointmentId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions('waitingRoom', ['fetchAppointmentQueue']),
    async assignQueuedAppointment() {
      try {
        await WaitingRoomApi.updateQueueAssignee(this.appointmentId);
        this.$router.push({
          name: 'appointment',
          params: {
            id: this.appointmentId,
          },
        });
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Couldn’t assign appointment',
          message: error.message,
        });
        this.fetchAppointmentQueue();
      }
    },
  },
};
</script>
