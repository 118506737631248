var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex w-full flex-col space-y-6" },
    [
      _c("base-label", { attrs: { for: "treatment" } }, [
        _vm._v(" Book a follow up "),
        _c("span", { staticClass: "text-gray-700" }, [_vm._v("*")]),
      ]),
      _c("div", { staticClass: "my-2 flex max-h-full items-center" }, [
        _c(
          "label",
          {
            staticClass: "mr-2 text-sm",
            class: {
              "text-gray-500": _vm.followUp && _vm.followUp.shouldFollowUp,
            },
            attrs: { for: "previous-journals" },
          },
          [_vm._v(" No ")]
        ),
        _c("div", { staticClass: "form-switch max-h-full" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.followUp.shouldFollowUp,
                expression: "followUp.shouldFollowUp",
              },
            ],
            staticClass: "form-switch-checkbox",
            attrs: { id: "previous-journals", type: "checkbox", name: "sign" },
            domProps: {
              checked: Array.isArray(_vm.followUp.shouldFollowUp)
                ? _vm._i(_vm.followUp.shouldFollowUp, null) > -1
                : _vm.followUp.shouldFollowUp,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.followUp.shouldFollowUp,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.followUp,
                          "shouldFollowUp",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.followUp,
                          "shouldFollowUp",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.followUp, "shouldFollowUp", $$c)
                  }
                },
                function ($event) {
                  return _vm.$emit("action")
                },
              ],
            },
          }),
          _c("label", {
            staticClass: "form-switch-label display-none",
            attrs: { for: "previous-journals" },
          }),
        ]),
        _c(
          "label",
          {
            staticClass: "text-sm",
            class: { "text-gray-500": !_vm.followUp.shouldFollowUp },
            attrs: { for: "previous-journals" },
          },
          [_vm._v(" Yes ")]
        ),
      ]),
      _vm.followUp.shouldFollowUp
        ? _c("div", [
            _c(
              "div",
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "mb-1 inline-block select-none text-base font-semibold text-gray-900",
                  },
                  [_vm._v("With")]
                ),
                _c("v-select", {
                  attrs: {
                    "append-to-body": "",
                    label: "name",
                    value: _vm.selectedOption,
                    options: _vm.followUpWithOptions,
                    placeholder: "Select follow up type",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("action")
                    },
                  },
                  model: {
                    value: _vm.selectedOption,
                    callback: function ($$v) {
                      _vm.selectedOption = $$v
                    },
                    expression: "selectedOption",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-7" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "mb-1 inline-block select-none text-base font-semibold text-gray-900",
                    attrs: { for: "followUpAt" },
                  },
                  [_vm._v("No later than")]
                ),
                _c("datepicker", {
                  attrs: {
                    id: "followUpAt",
                    format: "yyyy-MM-dd",
                    value: _vm.followUp.followUpAt,
                    "input-class":
                      "flex flex-1 font-semibold bg-white\n        border rounded cursor-pointer focus:outline-none py-0 h-10",
                    "wrapper-class": "",
                    "calendar-class":
                      "font-normal rounded shadow uppercase text-sm left-auto",
                    "monday-first": true,
                    "minimum-view": "day",
                    "maximum-view": "month",
                    placeholder: "Select a day",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("action")
                    },
                  },
                  model: {
                    value: _vm.followUp.followUpAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.followUp, "followUpAt", $$v)
                    },
                    expression: "followUp.followUpAt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "mb-2 flex items-center rounded bg-blue-700 px-2 py-3 text-sm font-bold text-white",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("fv-icon", { attrs: { icon: "Information" } }),
                    _c("p", { staticClass: "ml-1" }, [
                      _vm._v(
                        " Instructions and comments for internal use only. Not shown in journal. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("label", {
                  staticClass:
                    "mb-1 inline-block select-none text-base text-gray-900",
                  attrs: { for: "followAtComment" },
                }),
                _c("textarea-input", {
                  ref: "followAtComment",
                  attrs: {
                    id: "followAtComment",
                    rows: 4,
                    placeholder: "Internal comment for 2288",
                    name: "followAtComment",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("action")
                    },
                  },
                  model: {
                    value: _vm.followUp.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.followUp, "comment", $$v)
                    },
                    expression: "followUp.comment",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }