var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex w-full",
      class: _vm.showSelect ? "justify-between" : "",
    },
    [
      _vm.showSelect
        ? _c(
            "div",
            { staticClass: "relative flex-1 mr-2 clinic-select" },
            [
              _c("v-select", {
                staticClass: "font-medium rounded text-gray-700 w-full",
                attrs: {
                  id: "clinicName",
                  reduce: function (option) {
                    return option.id
                  },
                  value: _vm.clinic,
                  name: "clinicName",
                  label: "name",
                  placeholder: "Search for a clinic or city",
                  clearable: false,
                  options: _vm.options,
                  filterable: false,
                  loading: _vm.uiState === "loading",
                  "clear-search-on-select": false,
                },
                on: { input: _vm.getClinic, search: _vm.searchClinics },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-options",
                      fn: function (ref) {
                        var search = ref.search
                        var searching = ref.searching
                        var loading = ref.loading
                        return [
                          _c("div", { staticClass: "py-1" }, [
                            loading
                              ? _c("span", [_vm._v("Searching..")])
                              : _vm._e(),
                            !loading && search.length <= 1
                              ? _c("span", [_vm._v("Type to search")])
                              : _vm._e(),
                            !loading && search.length > 1
                              ? _c("span", [
                                  _vm._v(
                                    " Couldn't find any matches for \"" +
                                      _vm._s(search) +
                                      '" '
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1105669615
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "text-button",
        {
          attrs: { color: "dark", type: "button" },
          on: {
            click: function ($event) {
              return _vm.displayClinicMap(true)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "inline-flex items-center space-x-2" },
            [
              _c("fv-icon", {
                staticClass: "text-primary",
                attrs: { icon: "google-maps" },
              }),
              _c("span", [_vm._v("Find on map")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }