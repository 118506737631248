var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.animal
    ? _c(
        "div",
        [
          _c(
            "desc-list",
            [
              _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Name ")]),
                  _c("desc-data", [
                    _vm._v(" " + _vm._s(_vm.animal.name) + " "),
                  ]),
                ],
                1
              ),
              _vm.animal.animal_type
                ? _c(
                    "desc-item",
                    [
                      _c("desc-title", [_vm._v(" Species ")]),
                      _c("desc-data", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.animal.animal_type.name) +
                            ", " +
                            _vm._s(_vm.animal.race) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.animalBirthdate
                ? _c(
                    "desc-item",
                    [
                      _c("desc-title", [_vm._v(" Age & Gender ")]),
                      _c("desc-data", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.animalGender) +
                            ", " +
                            _vm._s(_vm.animalAge) +
                            " old, (" +
                            _vm._s(_vm.animalBirthdate) +
                            ") "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.animal.insurance_type
                ? _c(
                    "desc-item",
                    [
                      _c("desc-title", [_vm._v(" Insurance ")]),
                      _c("desc-data", [
                        _c("span", { attrs: { id: "insurance" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.animal.insurance_type.name) + " "
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.animal.insurance))]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Provided information ")]),
                  _c("desc-data", [
                    _vm.animal.description
                      ? _c("span", [_vm._v(_vm._s(_vm.animal.description))])
                      : _vm._e(),
                    !_vm.animal.description
                      ? _c("span", [_vm._v("--")])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "base-button",
            {
              staticClass: "flex-1 flex items-center w-auto mt-8",
              on: {
                click: function ($event) {
                  return _vm.$emit("edit")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "flex-shrink-0 h-5 w-5 text-gray-600",
                  attrs: {
                    fill: "currentColor",
                    viewBox: "0 0 24 24",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 \n0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z",
                    },
                  }),
                ]
              ),
              _c("span", { staticClass: "ml-2 flex-1" }, [
                _vm._v(" Edit information "),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }