var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col w-full" },
    [
      _vm.showFilters
        ? _c(
            "div",
            {
              staticClass: "w-full py-2 flex flex-col space-y-2 text-sm",
              attrs: { id: "templateFilters" },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between items-center space-x-4" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "language",
                          searchable: false,
                          clearable: false,
                          placeholder: "Select language",
                          reduce: function (language) {
                            return language.id
                          },
                          label: "name",
                          name: "language",
                          options: _vm.languages,
                        },
                        on: { input: _vm.fetch },
                        model: {
                          value: _vm.filters.languageId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "languageId", $$v)
                          },
                          expression: "filters.languageId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "templateAppointmentType",
                          placeholder: "Select case type",
                          label: "name",
                          reduce: function (type) {
                            return type.id
                          },
                          name: "templateAppointmentType",
                          options: _vm.appointmentTypes,
                        },
                        on: { input: _vm.fetch },
                        model: {
                          value: _vm.filters.appointmentTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "appointmentTypeId", $$v)
                          },
                          expression: "filters.appointmentTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showFilters
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: { content: "Hide filters" },
                              expression: "{content: 'Hide filters'}",
                            },
                          ],
                          staticClass: "focus:outline-none focus:ring px-1",
                          on: {
                            click: function ($event) {
                              _vm.showFilters = false
                            },
                          },
                        },
                        [
                          _c("fv-icon", {
                            staticClass: "text-gray-600",
                            attrs: { icon: "close" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "flex flex-col space-y-2" }, [
        _c(
          "div",
          { staticClass: "flex space-x-2 text-sm" },
          [
            _c(
              "v-select",
              {
                staticClass: "w-full",
                attrs: {
                  "reset-on-options-change": true,
                  placeholder: _vm.templateInputPlaceholder,
                  label: "title",
                  reduce: function (template) {
                    return template.id
                  },
                  options: _vm.templates,
                  loading: _vm.loading,
                  disabled: !_vm.templates.length,
                },
                model: {
                  value: _vm.templateId,
                  callback: function ($$v) {
                    _vm.templateId = $$v
                  },
                  expression: "templateId",
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "no-options" }, slot: "no-options" },
                  [
                    _vm._v(
                      " Couldn't find any templates matching the given filters. "
                    ),
                  ]
                ),
              ]
            ),
            !_vm.showFilters
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: { content: "Show template filters" },
                        expression: "{content: 'Show template filters'}",
                      },
                    ],
                    staticClass: "focus:outline-none focus:ring px-1",
                    on: {
                      click: function ($event) {
                        _vm.showFilters = !_vm.showFilters
                      },
                    },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "text-gray-600",
                      attrs: {
                        icon: _vm.showFilters ? "chevron-up" : "filter",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.selectedTemplate
          ? _c(
              "div",
              { staticClass: "w-full flex justify-between" },
              [
                !!_vm.journalContent
                  ? _c(
                      "div",
                      { staticClass: "inline-flex space-x-2" },
                      [
                        _c(
                          "base-button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content:
                                    "Append the template to the end of current text",
                                },
                                expression:
                                  "{content: 'Append the template to the end of current text'}",
                              },
                            ],
                            attrs: { size: "sm" },
                            on: { click: _vm.appendTemplateToTreatment },
                          },
                          [_vm._v(" Append to text ")]
                        ),
                        _c(
                          "base-button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content:
                                    "Replace the current text with the template.",
                                },
                                expression:
                                  "{content: 'Replace the current text with the template.'}",
                              },
                            ],
                            attrs: { size: "sm" },
                            on: { click: _vm.replaceTextWithTemplate },
                          },
                          [_c("span", [_vm._v("Replace text")])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.journalContent
                  ? _c(
                      "div",
                      { staticClass: "inline-flex space-x-2" },
                      [
                        _c(
                          "base-button",
                          { on: { click: _vm.replaceTextWithTemplate } },
                          [_vm._v(" Use ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedTemplate
                  ? _c(
                      "base-button",
                      {
                        attrs: { size: "sm" },
                        on: {
                          click: function ($event) {
                            _vm.viewTemplate = true
                          },
                        },
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "eye" } })],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.viewTemplate },
          on: {
            close: function ($event) {
              _vm.viewTemplate = false
            },
          },
        },
        [
          _vm.selectedTemplate
            ? _c("div", {}, [
                _c("div", { staticClass: "prose" }, [
                  _c("p", { staticClass: "font-black text-xl" }, [
                    _vm._v(" " + _vm._s(_vm.selectedTemplate.title) + " "),
                  ]),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.selectedTemplate.body) },
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }