var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border rounded p-3 mt-2 w-full relative" },
    [
      _vm.clinicIsLoading
        ? _c("spinner-overlay", {
            staticClass: "flex justify-center items-center",
            attrs: { color: "transparent", loading: _vm.clinicIsLoading },
          })
        : _vm._e(),
      _vm.clinic
        ? _c("div", { staticClass: "space-y-6" }, [
            _c(
              "div",
              { staticClass: "font-bold flex justify-between text-base" },
              [
                _c("p", { staticClass: "font-display text-lg tracking-wide" }, [
                  _vm._v(" " + _vm._s(_vm.clinic.name) + " "),
                ]),
              ]
            ),
            _c("div", [
              _c("p", { staticClass: "mb-2" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Email:"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.clinic.referral_email || _vm.clinic.email) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-2" }, [
                _c("span", { staticClass: "font-semibold" }, [_vm._v("Tel:")]),
                _vm._v(" " + _vm._s(_vm.clinic.phone) + " "),
              ]),
              _c("p", [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Address:"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.clinic.adress) +
                    " " +
                    _vm._s(
                      _vm.clinic.postcode ? ", " + _vm.clinic.postcode : ""
                    ) +
                    " " +
                    _vm._s(_vm.clinic.city ? ", " + _vm.clinic.city : "") +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex justify-end" },
              [
                _c(
                  "text-button",
                  {
                    attrs: { color: "primary", type: "button" },
                    on: { click: _vm.copyClinicData },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inline-flex items-center space-x-2" },
                      [
                        _c("fv-icon", {
                          attrs: { icon: _vm.copied ? "check-mark" : "copy" },
                        }),
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v("Copy contact information"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }