<template>
  <base-modal v-if="show" :show="show" @close="$emit('close')">
    <div class="relative">
      <div
        v-if="uiState === 'finishing'"
        class="relative flex min-h-full flex-col justify-center space-y-4 text-center"
        :style="{ minHeight: '70vh' }"
      >
        <img
          :src="require('@/assets/svg/playing_fetch.svg')"
          class="mx-auto mt-6 h-1/5 w-1/3 object-cover"
        />
        <base-spinner
          :loading="uiState === 'finishing'"
          class="mx-auto"
          size="xl"
        />
        <p>Finishing up..</p>
      </div>

      <div
        v-show="uiState === 'error'"
        class="relative flex min-h-full flex-col justify-center space-y-4 text-center"
        :style="{ minHeight: '70vh' }"
      >
        <p class="rounded bg-red-100 p-4 text-red-900">
          {{ error }}
        </p>
        <p>Please submit a support ticket on the issue.</p>
      </div>

      <div
        v-show="uiState === 'finished'"
        id="finishModal"
        class="flex flex-col justify-center space-y-4 text-center"
        :style="{ minHeight: '70vh' }"
      >
        <p class="mt-2 font-display text-3xl font-black">
          Nice one {{ user.first_name }}!
        </p>
        <p class="text-lg">The journal has been sent to the animal owner.</p>
        <img
          :src="require('@/assets/svg/undraw_relaxing_walk_mljx.svg')"
          class="mx-auto mt-6 h-2/5 w-2/3 object-cover"
        />
        <p class="text-lg">
          Yet another satisfied customer,
          <span class="font-semibold text-primary">amazing job 👍</span>!
        </p>
        <p class="text-lg">
          You have already helped Firstvet users with
          <span id="counter" class="font-display text-3xl font-black" /> cases.
        </p>
        <!-- <transition name="fade">
          <span v-if="totalCount > 9">Not bad..</span>
        </transition> -->
        <canvas
          id="confetti-canvas"
          class="absolute inset-0 hidden h-full w-full"
        />
      </div>

      <div v-if="uiState === 'toFinish'" class="flex flex-col space-y-4">
        <p
          class="mb-4 font-display text-xl font-black tracking-wide text-gray-900"
        >
          Journal preview
        </p>

        <Journal
          :journal="journal"
          :appointment-type="appointment.appointment_type"
          :user-name="appointment.booking.veterinary.display_name"
          :user-avatar="appointment.booking.veterinary.avatar || ''"
          :filtered-additional-info="radioFields"
          :product-recommendations="productRecommendations"
        />

        <div
          v-if="isChatMeeting"
          class="w-full rounded-lg bg-gray-200 bg-opacity-50 px-4 py-3 text-navy"
        >
          <title-row> Send journal email to customer?</title-row>
          <div class="my-2 flex max-h-full items-center">
            <label
              class="mr-2 text-sm"
              :class="{ 'text-gray-500': sendEmail }"
              for="copy-images"
            >
              No
            </label>
            <div class="form-switch max-h-full">
              <input
                id="copy-images"
                v-model="sendEmail"
                type="checkbox"
                name="sign"
                class="form-switch-checkbox"
              />
              <label for="copy-images" class="form-switch-label display-none" />
            </div>
            <label
              class="text-sm"
              for="copy-images"
              :class="{ 'text-gray-500': !sendEmail }"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
    <template #buttons>
      <div v-if="uiState === 'finished'" class="w-full">
        <base-button class="w-full" color="dark" @click="close">
          Continue
        </base-button>
      </div>

      <div
        v-if="uiState === 'toFinish'"
        class="flex w-full justify-end space-x-2 p-2"
      >
        <base-button class="w-1/3" color="cancel" @click="close">
          Cancel
        </base-button>
        <base-button
          class="w-1/3"
          color="dark"
          :disabled="journalIsSaving"
          @click="finish"
        >
          {{ isChatMeeting ? 'Sign' : 'Send' }}
        </base-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import Journal from '@/components/journal/Journal';
import { generateJournalRadioButtons } from '@/config/journal-config';
import { VetStatsApi, ChatApi } from '@/api';
import generateConfetti from '@/plugins/confetti';

export default {
  components: {
    Journal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    journal: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    appointmentGtm: {
      type: Object,
      required: false,
      default: null,
    },
    productRecommendations: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      uiState: 'toFinish',
      confetti: null,
      totalCount: 0,
      error: null,
      sendEmail: false,
    };
  },
  computed: {
    ...mapState('journal', { journalIsSaving: 'loading' }),
    ...mapState('user', ['user']),
    ...mapGetters('appointment', { serviceChannel: 'getServiceChannel' }),
    radioFields() {
      return generateJournalRadioButtons({
        countryId: this.appointment.booking.country_id,
        isPreScreeningMeeting: this.isPreScreeningMeeting,
        isVeterinaryConsult: !this.isPreScreeningMeeting,
      });
    },
    isChatMeeting() {
      return this.serviceChannel === 'chat';
    },
  },
  beforeDestroy() {
    const canvas = document.getElementById('confetti-canvas');
    if (canvas) {
      canvas.classList.remove('z-50');
      canvas.classList.add('hidden');
    }

    if (this.confetti) {
      this.confetti.clear();
    }
  },
  methods: {
    ...mapActions('journal', ['postFinishedJournal']),

    async finish() {
      this.scrollToTop();
      this.uiState = 'finishing';
      this.error = null;
      try {
        const payload = {
          ...this.journal,
          send_email: this.isChatMeeting ? this.sendEmail : true,
        };
        await this.postFinishedJournal(payload);

        if (this.isChatMeeting) {
          await Promise.all([
            ChatApi.closeConversation(this.appointment.id),
            ChatApi.endConversation(this.appointment.id),
          ]);
        }

        this.$gtm.trackEvent({
          event: 'clicked_save_journal',
          appointment: this.appointmentGtm,
          productRecommendations: this.productRecommendations,
        });
        this.uiState = 'finished';
        this.generateFinishedView();
      } catch (error) {
        this.uiState = 'error';
        this.error = error;
      }
    },
    async generateFinishedView() {
      this.$nextTick(async () => {
        const canvas = document.getElementById('confetti-canvas');
        if (canvas) {
          canvas.classList.add('z-50');
          canvas.classList.remove('hidden');
          this.confetti = generateConfetti(canvas, {
            size: 1.5,
            clock: 10,
            rotate: true,
          });
          this.confetti.render();
        }
        await this.setTotalCount();
      });
    },
    async setTotalCount() {
      const { data } = await VetStatsApi.getTotalCount();
      const { count } = data;
      const counter = document.getElementById('counter');
      if (counter) {
        counter.style.setProperty('--num', 0);
        counter.style.setProperty('--num', count);
      }
      setTimeout(() => {
        this.totalCount = count;
      }, 2500);
    },
    scrollToTop() {
      document.getElementById('baseModalWrapper').scroll(0, 0);
    },
    close() {
      if (this.uiState === 'toFinish') {
        this.$gtm.trackEvent({
          event: 'clicked_cancel_preview_journal',
          appointment: this.appointmentGtm,
          productRecommendations: this.productRecommendations,
        });
      }
      this.$emit('close');
    },
  },
};
</script>
<style lang="postcss" scoped>
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

#counter {
  transition: --num 2s;
  counter-reset: num var(--num);
}

#counter::after {
  content: counter(num);
}
</style>
>
