<template>
  <div
    class="w-full space-y-2"
  >
    <base-label>
      Product recommendations
    </base-label>

    <div>
      <v-select
        ref="productSelect"
        value=""
        class="product-select font-medium rounded text-gray-700 w-full text-sm"
        label="name"
        :options="products"
        :filterable="false"
        :autoscroll="false"
        :loading="uiState === 'loading'"
        :close-on-select="false"
        :clear-search-on-blur="() => false"
        :clear-search-on-select="false"
        placeholder="Search for a product"
        @search="searchProducts"
      >
        <template
          v-slot:option="option"
        >
          <div
            class="flex group flex-wrap"
            @click="selectProduct(option)"
          >
            <div
              class="flex w-1/4 justify-items-center justify-center"
              style="max-width: 100px"
            >
              <img
                :src="option.image_url"
                class="w-full h-24 object-contain pr-2"
              >
            </div>
            <div
              class="flex flex-col flex-1 p-2 whitespace-pre-wrap"
            >
              <strong
                class="max-w-full break-normal"
              >{{ option.name }}</strong>
              <div class="mb-2">
                <span>{{ option.brand_name }}</span> -
                <span>{{ option.animal_types.join(', ') }}</span>
              </div>
              <div
                class="mb-2 rounded-full p-1 w-auto mr-auto text-xs"
                :class="option.is_available ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'"
              >
                {{ option.is_available ? 'In stock' : 'Out of stock' }}
              </div>
              <a
                class="invisible text-xs group-hover:visible hover:underline self-start"
                :href="option.url"
                target="_blank"
                @click.stop
              >
                View in store
                <font-awesome-icon
                  class="ml-1"
                  icon="external-link-alt"
                />
              </a>
            </div>
          </div>
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            We couldn't find any products for <em>{{ search }}</em>.
          </template>
          <em
            v-else
            style="opacity: 0.5"
          >Start typing to search for a product.</em>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { EcomApi } from '@/api';

export default {
  props: {
    appointmentTypeId: {
      type: Number,
      required: true,
      default: 0,
    },
    animalTypeId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      products: [],
      appointmentTypeProducts: [],
      searchQuery: '',
      uiState: 'idle',
    };
  },
  computed: {
    ...mapGetters('user', { userId: 'getUserId' }),
  },
  watch: {
    appointmentTypeId() {
      if (!this.searchQuery) {
        this.getProductsForAppointmentType();
      }
    },
  },
  mounted() {
    this.getProductsForAppointmentType();
  },
  methods: {
    async getProductsForAppointmentType() {
      try {
        const { data: products } = await EcomApi.getProducts({
          body: {
            appointmentType: this.appointmentTypeId,
            animalTypeId: this.animalTypeId,
          },
          userId: this.userId,
        });

        this.appointmentTypeProducts = products;
        this.products = products;
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Could not fetch products',
          text: error.message,
        });
      }
    },

    async fetch(searchQuery = null) {
      if (!searchQuery) {
        this.products = this.appointmentTypeProducts;
        return Promise.resolve();
      }

      this.uiState = 'loading';

      const body = {
        query: searchQuery,
        appointmentType: this.appointmentTypeId,
        animalTypeId: this.animalTypeId,
      };

      try {
        const { data: products } = await EcomApi.getProducts({
          body,
          userId: this.userId,
        });

        this.products = products;
        this.uiState = 'idle';
      } catch (error) {
        this.uiState = 'error';
        this.$notify({
          group: 'error',
          title: 'Could not fetch products',
          text: error.message,
        });
      }

      return this.products;
    },

    // eslint-disable-next-line func-names
    searchProducts: debounce(async function(search) {
      this.searchQuery = search;
      await this.fetch(search);
    }, 500),

    selectProduct(selectedProduct) {
      this.$emit('select', selectedProduct);

      // Manually trigger blur to close dropdown after selecting.
      // Needed (in combination with closeOnSelect=false) since external links didn't work -
      // the dropdown was closed before link recieved click event.
      const { searchEl } = this.$refs.productSelect;

      if (searchEl) {
        searchEl.blur();
      }
    },
  },
};
</script>

<style lang="scss">
.product-select .vs__dropdown-menu {
  max-height: 300px;
}
</style>
