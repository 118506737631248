var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-2" },
    [
      _c("base-label", { attrs: { for: "appointmentType" } }, [
        _vm._v(" Area for discussion "),
        _c("span", { staticClass: "text-gray-700" }, [_vm._v("*")]),
        _vm.showValidationErrors && _vm.error
          ? _c(
              "span",
              {
                staticClass: "text-red-800 p-2 ml-8 font-semibold text-center",
              },
              [_vm._v(_vm._s(_vm.error))]
            )
          : _vm._e(),
      ]),
      _c(
        "v-select",
        _vm._b(
          {
            attrs: {
              size: "sm",
              value: _vm.selectedAppointmentType,
              options: _vm.appointmentTypes,
              placeholder: "Select area for discussion",
              reduce: function (at) {
                return at.id
              },
              label: "name",
              "value-attr": "id",
              searchable: true,
              disabled: _vm.uiState !== "idle",
              clearable: false,
            },
            on: {
              input: function ($event) {
                return _vm.storeAppointmentType($event)
              },
            },
          },
          "v-select",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }