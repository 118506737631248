var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-row items-center lg:flex-col",
      attrs: { id: "panelNav" },
    },
    _vm._l(_vm.tabListFiltered, function (nav, i) {
      return _c(
        "div",
        { key: i, staticClass: "flex w-full items-center text-base" },
        [
          _vm.tab
            ? _c(
                "nav-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.left",
                      value: {
                        content: nav.tooltip,
                        classes: "tooltip-hidden",
                      },
                      expression:
                        "{ content: nav.tooltip, classes: 'tooltip-hidden' }",
                      modifiers: { left: true },
                    },
                  ],
                  staticClass: "relative flex-1 py-3",
                  class: {
                    "opacity-50": _vm.editComment,
                    "tab-link-active": _vm.tab.title.includes(nav.title),
                  },
                  attrs: {
                    color: "dark",
                    disabled:
                      _vm.editComment ||
                      _vm.appointmentLoading ||
                      !_vm.appointment.id ||
                      !_vm.appointment.booking,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("select-tab", nav)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      nav.icon
                        ? _c("fv-icon", {
                            attrs: { icon: nav.icon, size: "md" },
                          })
                        : _vm._e(),
                      _vm.showCountValue(nav.countValue)
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute top-0 right-0 mr-4 mt-1 h-4 w-4 rounded-full bg-primary text-xs font-semibold text-white",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.appointment[nav.countValue] &&
                                      _vm.appointment[nav.countValue].length) ||
                                      _vm.countValue
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }