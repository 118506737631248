var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-12", attrs: { id: "journalTab" } },
    [
      _vm.storeJournal && _vm.storeJournal.status
        ? _c("Journal", {
            attrs: {
              journal: _vm.storeJournal,
              "appointment-type": _vm.appointment.appointment_type,
              "user-name": _vm.appointment.booking.veterinary.display_name,
              "user-avatar": _vm.appointment.booking.veterinary.avatar || "",
              timezone: _vm.appointment.booking.country.timezone,
              "filtered-additional-info": _vm.radioFields,
              "product-recommendations": _vm.showProductRecommendations
                ? _vm.productRecommendations
                : null,
            },
          })
        : _vm._e(),
      _vm.storeJournal && !_vm.storeJournal.status
        ? _c(
            "ValidationObserver",
            {
              ref: "observer",
              staticClass: "relative flex flex-wrap space-y-8",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("ValidationProvider", {
                staticClass: "w-full",
                attrs: { rules: "required", name: "appointmentType" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return [
                          _vm.appointment.animal
                            ? _c("AppointmentTypeSelect", {
                                attrs: {
                                  name: "appointmentType",
                                  error: errors[0],
                                  "show-validation-errors":
                                    _vm.showValidationErrors,
                                  "country-id":
                                    _vm.appointment.booking.country_id,
                                  "animal-type-id":
                                    _vm.appointment.animal.type_id,
                                  "service-id":
                                    _vm.appointment.booking.service.id,
                                },
                                model: {
                                  value: _vm.appointment.appointment_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appointment,
                                      "appointment_type",
                                      $$v
                                    )
                                  },
                                  expression: "appointment.appointment_type",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3450995418
                ),
              }),
              _vm._l(_vm.textFields, function (textField) {
                return _c(
                  "div",
                  {
                    key: textField.id,
                    staticClass: "w-full",
                    class: [textField.classList],
                  },
                  [
                    _c("ValidationProvider", {
                      staticClass: "flex w-full flex-col space-y-2",
                      attrs: {
                        rules: textField.validationSettings.validate
                          ? textField.validationSettings.validation
                          : "",
                        name: textField.fieldName,
                        vid: textField.id,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "base-label",
                                  { attrs: { for: textField.id } },
                                  [
                                    _vm._v(" " + _vm._s(textField.label) + " "),
                                    textField.validationSettings.validate
                                      ? _c(
                                          "span",
                                          { staticClass: "text-gray-700" },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "transition",
                                      { attrs: { name: "fade" } },
                                      [
                                        _vm.showValidationErrors &&
                                        errors.length
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-8 p-2 text-center font-semibold text-red-800",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                !textField.useEditor
                                  ? _c("textarea-input", {
                                      attrs: {
                                        id: textField.id,
                                        rows: textField.rows,
                                        placeholder: textField.placeholder,
                                        name: textField.fieldName,
                                      },
                                      on: { input: _vm.autoSave },
                                      model: {
                                        value: _vm.journal[textField.model],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.journal,
                                            textField.model,
                                            $$v
                                          )
                                        },
                                        expression: "journal[textField.model]",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
              !_vm.isPreScreeningMeeting && _vm.isSwedishPlatform
                ? _c("DiagnosisCodesSelect", {
                    attrs: {
                      "show-validation-errors": _vm.showValidationErrors,
                    },
                    on: { updated: _vm.autoSave },
                    model: {
                      value: _vm.journal.diagnosis_codes,
                      callback: function ($$v) {
                        _vm.$set(_vm.journal, "diagnosis_codes", $$v)
                      },
                      expression: "journal.diagnosis_codes",
                    },
                  })
                : _vm._e(),
              !_vm.isPreScreeningMeeting && _vm.journal
                ? _c("ValidationProvider", {
                    staticClass: "flex w-full flex-col space-y-2",
                    attrs: {
                      rules: "required|min:8",
                      name: "treatment",
                      tag: "div",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c(
                                "base-label",
                                { attrs: { for: "treatment" } },
                                [
                                  _vm._v(" Treatment / recommendation "),
                                  _c("span", { staticClass: "text-gray-700" }, [
                                    _vm._v("*"),
                                  ]),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      _vm.showValidationErrors && errors.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-8 p-2 text-center font-semibold text-red-800",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.appointment.animal
                                ? _c("TreatmentTemplateSelect", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      "animal-type-id":
                                        _vm.appointment.animal.type_id,
                                      "journal-content": _vm.journal.treatment,
                                    },
                                    on: {
                                      appendSelected:
                                        _vm.appendTemplateToTreatment,
                                      replaceSelected:
                                        _vm.replaceContentWithTemplate,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "w-full max-w-full" },
                                [
                                  _c("ckeditor", {
                                    attrs: {
                                      id: "treatment",
                                      placeholder: "Treatment / recommendation",
                                      "aria-placeholder":
                                        "Treatment / recommendation",
                                      editor: _vm.editor,
                                      config: _vm.editorConfig,
                                      name: "treatment",
                                    },
                                    on: { input: _vm.autoSave },
                                    model: {
                                      value: _vm.journal.treatment,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.journal, "treatment", $$v)
                                      },
                                      expression: "journal.treatment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      266874239
                    ),
                  })
                : _vm._e(),
              _vm.showProductRecommendations
                ? [
                    _vm.appointment.animal
                      ? _c("ProductSelect", {
                          attrs: {
                            "appointment-type-id": _vm.appointment
                              .appointment_type
                              ? _vm.appointment.appointment_type.id
                              : 0,
                            "animal-type-id": _vm.appointment.animal.type_id,
                          },
                          on: { select: _vm.addProductRecommendation },
                        })
                      : _vm._e(),
                    _c("ProductRecommendations", {
                      attrs: {
                        products: _vm.productRecommendations,
                        loading: _vm.productRecommendationsLoading,
                      },
                      on: { remove: _vm.removeProductRecommendation },
                    }),
                  ]
                : _vm._e(),
              !_vm.isPreScreeningMeeting
                ? _c("ValidationProvider", {
                    staticClass: "w-full",
                    attrs: { rules: "required", name: "referralType" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c("ReferralTypeSelect", {
                                attrs: {
                                  name: "referralType",
                                  error: errors[0],
                                  "show-validation-errors":
                                    _vm.showValidationErrors,
                                  "referral-id": _vm.journal.referral_id,
                                },
                                on: {
                                  action: function ($event) {
                                    ;(_vm.journal.referral_id = $event),
                                      _vm.autoSave()
                                  },
                                },
                                model: {
                                  value: _vm.journal.referral_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.journal, "referral_id", $$v)
                                  },
                                  expression: "journal.referral_id",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3443238552
                    ),
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex w-full flex-col space-y-6" },
                _vm._l(_vm.radioFields, function (button) {
                  return _c(
                    "div",
                    { key: button.id, staticClass: "w-full" },
                    [
                      _c("radio-select", {
                        attrs: {
                          title: button.title,
                          error:
                            _vm.journal[button.model] === null &&
                            _vm.showValidationErrors,
                          model: _vm.journal[button.model],
                          name: button.fieldName,
                          rules: button.required ? "required" : "",
                        },
                        on: {
                          action: function ($event) {
                            return _vm.handleRadioValue(button.model, $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.countryId === _vm.svCountryId
                ? _c("follow-up", {
                    attrs: { "follow-up": _vm.followUp },
                    on: { action: _vm.autoSave },
                  })
                : _vm._e(),
              _vm.journal.appointment_data
                ? _c(
                    "div",
                    { staticClass: "flex w-full flex-col space-y-6" },
                    _vm._l(_vm.appointmentDataCheckbox, function (checkbox) {
                      return _c(
                        "div",
                        {
                          key: checkbox.id,
                          staticClass: "flex w-full items-center gap-2",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.journal.appointment_data[checkbox.model],
                                expression:
                                  "journal.appointment_data[checkbox.model]",
                              },
                            ],
                            attrs: { id: checkbox.id, type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.journal.appointment_data[checkbox.model]
                              )
                                ? _vm._i(
                                    _vm.journal.appointment_data[
                                      checkbox.model
                                    ],
                                    null
                                  ) > -1
                                : _vm.journal.appointment_data[checkbox.model],
                            },
                            on: {
                              input: _vm.autoSave,
                              change: function ($event) {
                                var $$a =
                                    _vm.journal.appointment_data[
                                      checkbox.model
                                    ],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.journal.appointment_data,
                                        checkbox.model,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.journal.appointment_data,
                                        checkbox.model,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.journal.appointment_data,
                                    checkbox.model,
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: checkbox.id } }, [
                            _vm._v(_vm._s(checkbox.title)),
                          ]),
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: checkbox.tooltip,
                                },
                                expression:
                                  "{\n            content: checkbox.tooltip,\n          }",
                              },
                            ],
                            staticClass: "text-gray-600",
                            attrs: { icon: "info-circle" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex w-full flex-col space-y-6" },
                _vm._l(_vm.prescriptionDataCheckbox, function (checkbox) {
                  return _c(
                    "div",
                    {
                      key: checkbox.id,
                      staticClass: "flex w-full items-center gap-2",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.journal[checkbox.model],
                            expression: "journal[checkbox.model]",
                          },
                        ],
                        attrs: { id: checkbox.id, type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.journal[checkbox.model])
                            ? _vm._i(_vm.journal[checkbox.model], null) > -1
                            : _vm.journal[checkbox.model],
                        },
                        on: {
                          input: _vm.autoSave,
                          change: function ($event) {
                            var $$a = _vm.journal[checkbox.model],
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.journal,
                                    checkbox.model,
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.journal,
                                    checkbox.model,
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.journal, checkbox.model, $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: checkbox.id } }, [
                        _vm._v(_vm._s(checkbox.title)),
                      ]),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: checkbox.tooltip,
                            },
                            expression:
                              "{\n            content: checkbox.tooltip,\n          }",
                          },
                        ],
                        staticClass: "text-gray-600",
                        attrs: { icon: "info-circle" },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full flex-col items-center space-y-4 border-t-2 bg-white bg-opacity-75 py-8",
                },
                [
                  _c("div", [
                    !_vm.showValidationErrors
                      ? _c("div", [
                          _vm.journal.updated_at !== _vm.journal.created_at
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "inline-flex items-center space-x-2",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Last saved at " +
                                        _vm._s(_vm.formattedDate)
                                    ),
                                  ]),
                                  _c("fv-icon", {
                                    staticClass: "text-fv-green",
                                    attrs: { icon: "check-mark", size: "md" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.showValidationErrors
                      ? _c(
                          "div",
                          { staticClass: "flex items-center space-x-4" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "inline-flex items-center space-x-2 text-red-900",
                                on: {
                                  click: function ($event) {
                                    _vm.showValidationErrors = false
                                  },
                                },
                              },
                              [
                                _c("fv-icon", { attrs: { icon: "warning" } }),
                                _c("span", [
                                  _vm._v(
                                    "The journal is missing required information."
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "inline-flex w-full flex-shrink-0 justify-end space-x-2",
                    },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "w-full",
                          attrs: { loading: _vm.loading, color: "dark" },
                          on: { click: _vm.validate },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.loading ? "Saving" : "Preview"
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.showJournalPreview
        ? _c("FinishJournal", {
            attrs: {
              show: _vm.showJournalPreview,
              journal: _vm.journal,
              appointment: _vm.appointment,
              "appointment-gtm": _vm.appointmentGtm,
              "product-recommendations": _vm.showProductRecommendations
                ? _vm.productRecommendations
                : null,
            },
            on: {
              close: function ($event) {
                _vm.showJournalPreview = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }