<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-2">
      <base-button
        v-if="enableUserToUseApoExIntegration"
        color="primary"
        @click="generateApoExPrescriptionLink"
        >Generate ApoEx e-presciption
      </base-button>

      <div v-if="signedStatus">
        <title-row>Prescription information</title-row>
        <p class="py-4">{{ prescription }}</p>
      </div>
      <textarea-input
        v-else
        id="prescription"
        ref="prescriptionInput"
        v-model="prescription"
        v-focus
        :disabled="disableInput"
        :rows="8"
        placeholder="Enter prescription information"
        @input="autoSave"
      />

      <base-button
        v-if="apoexPrescriptionLinkGenerated && enableUserToUseApoExIntegration"
        color="primary"
        @click="populateApoExPrescriptionDetails"
        >Populate ApoEx e-prescription details
      </base-button>

      <div
        v-if="storeJournal.prescription"
        class="inline-flex rounded-lg bg-primary-lighter bg-opacity-50 px-4 py-3 text-navy"
      >
        <p class="inline-flex items-center space-x-1">
          <span>Last saved at {{ formattedDate }}</span>
          <fv-icon size="md" class="text-fv-green" icon="check-mark" />
        </p>
      </div>
      <div v-if="!signedStatus && showSignButton">
        <base-button
          color="primary"
          :disabled="disableSigned"
          @click="signPrescription"
        >
          Sign prescription
        </base-button>
      </div>
    </div>

    <base-modal
      :show="showApoExApiKeyModal"
      @close="showApoExApiKeyModal = false"
    >
      <slot>
        <h2
          class="mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl"
        >
          <span>ApoEx API Key</span>
        </h2>
        <div class="mb-4 rounded-lg bg-gray-50 p-4 text-gray-600">
          <p>
            To generate an ApoEx e-prescription you will need to supply your API
            key below and submit.
          </p>
        </div>
        <base-input
          id="apoexApiKey"
          v-model="apoexApiKey"
          v-focus
          type="text"
          class="w-1/2"
          placeholder="Enter your API key"
          required
        />
      </slot>
      <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
        <base-button color="cancel" @click="showApoExApiKeyModal = false">
          Cancel
        </base-button>
        <base-button
          :loading="loading"
          color="primary"
          :disabled="loading"
          @click="saveApoExApiKey"
        >
          Submit
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { AppointmentApi, UserApi } from '@/api';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  data() {
    return {
      uiState: 'idle',
      prescription: '',
      preventAutoSave: false,
      apoexApiKey: '',
      apoexPrescriptionId: '',
      apoexPrescriptionLinkGenerated: false,
      showApoExApiKeyModal: false,
      loading: false,
      enableUserToUseApoExIntegration: false,
      userHasApoexApiKey: false,
    };
  },

  computed: {
    ...mapGetters('journal', {
      storeJournal: 'getJournal',
      storePrescription: 'getPrescription',
      showSignButton: 'getIsFinished',
    }),
    ...mapGetters('user', {
      user: 'getUser',
    }),
    ...mapGetters({
      platform: 'getPlatform',
    }),
    formattedDate() {
      // date can be either from prescription or journal due to backwards compatibility
      const dateToFormat =
        this.storePrescription?.updated_at || this.storeJournal?.updated_at;

      return format(
        zonedTimeToUtc(dateToFormat, 'Europe/Stockholm'),
        'yyyy-MM-dd HH:mm:ss'
      );
    },
    disableInput() {
      return this.uiState === 'loading' || !!this.storePrescription?.signed;
    },
    disableSigned() {
      return (
        this.uiState === 'loading' ||
        !!this.storePrescription?.signed ||
        this.prescription === ''
      );
    },
    signedStatus() {
      return !!this.storePrescription?.signed;
    },
  },
  async mounted() {
    await this.getPrescriptions();
    this.prescription = this.storePrescription?.prescription || '';

    // Temporary as only users with ApoEx api key saved already can use the integration.
    if (
      this.platform === 'sv' &&
      this.user.hasApoexApiKey &&
      !this.storePrescription?.signed
    ) {
      this.enableUserToUseApoExIntegration = true;
    }
  },

  methods: {
    ...mapActions('journal', [
      'postJournalData',
      'getJournalPrescriptions',
      'signJournalPrescription',
      'addJournalPrescription',
    ]),
    async save() {
      // A signed prescription cannot be edited
      if (!this.signedStatus && !this.disableInput) {
        this.uiState = 'updating';
        this.preventAutoSave = true;

        // New prescription API
        await this.addJournalPrescription(this.prescription);
        this.uiState = 'idle';

        setTimeout(() => {
          this.preventAutoSave = false;
        }, 1000);
      }
    },

    // eslint-disable-next-line func-names
    autoSave: debounce(function () {
      this.save();
    }, 1000),

    async saveApoExApiKey() {
      this.loading = true;

      try {
        const user = { ...this.user, apoex_api_key: this.apoexApiKey };
        await UserApi.updateUserDetails(user);

        this.$emit('updated', user);
        this.loading = false;

        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: 'User details have been updated',
        });
        this.showApoExApiKeyModal = false;
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: 'Please try again or submit a support ticket',
        });
        this.loading = false;
      }
    },

    async generateApoExPrescriptionLink() {
      if (!this.user.hasApoexApiKey && !this.apoexApiKey) {
        this.showApoExApiKeyModal = true;
        return;
      }

      const { data } = await AppointmentApi.getApoExPrescriptionLink(
        this.$route.params.id
      );

      if (data.url) {
        window.open(data.url, '_blank');
        this.apoexPrescriptionId = data.id;
        this.apoexPrescriptionLinkGenerated = true;
      }
    },

    async populateApoExPrescriptionDetails() {
      const { data } = await AppointmentApi.getApoExPrescriptionDetails(
        this.$route.params.id,
        this.apoexPrescriptionId
      );

      if (data) {
        this.prescription += this.prescription ? '\n' : '';

        data.forEach(item => {
          this.prescription += item;
        });

        this.save();
        this.apoexPrescriptionId = '';
        this.apoexPrescriptionLinkGenerated = false;
      }
    },

    async getPrescriptions() {
      this.uiState = 'loading';
      await this.getJournalPrescriptions();
      this.uiState = 'idle';
    },

    async signPrescription() {
      this.uiState = 'loading';
      await this.signJournalPrescription(this.prescription);
      this.enableUserToUseApoExIntegration = false;
      this.uiState = 'idle';
    },
  },
};
</script>
