<template>
  <div class="flex flex-col">
    <appointment-upload
      :appointment-id="appointment.id"
      :uploads="appointment.uploads"
      :extensions="['.pdf', '.doc', '.docx', '.rtf', '.jpg', '.png', '.jpeg']"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppointmentUpload from '../AppointmentUpload.vue';

export default {
  components: { AppointmentUpload },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('appointment', { appointment: 'getAppointment' }),
  },
};
</script>
