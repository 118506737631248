var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-wrap" }, [
    _vm.journal.prescription
      ? _c("div", { staticClass: "flex flex-col space-y-6" }, [
          _c("p", {}, [
            _vm._v(
              " " +
                _vm._s(_vm.$formatDateTime(_vm.appointment.journal.notified)) +
                " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            [
              _c(
                "base-label",
                { attrs: { for: "prescription", "text-size": "xl" } },
                [_vm._v(" Prescription ")]
              ),
              _c("nl2br", {
                attrs: { tag: "p", text: _vm.journal.prescription },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            [
              _c(
                "base-label",
                { attrs: { for: "appointmentType", "text-size": "xl" } },
                [_vm._v(" Veterinarian ")]
              ),
              _c("nl2br", {
                attrs: {
                  tag: "p",
                  text: _vm.appointment.booking.veterinary.display_name,
                },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _c("p", { staticClass: "w-full", attrs: { closable: false } }, [
            _vm._v(" No prescription was ordered. "),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }