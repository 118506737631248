var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-modal",
        {
          attrs: { show: _vm.show },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "buttons",
                fn: function () {
                  return [
                    _vm.uiState === "finished"
                      ? _c(
                          "div",
                          { staticClass: "w-full" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "w-full",
                                attrs: { color: "dark" },
                                on: { click: _vm.close },
                              },
                              [_vm._v(" Continue ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.uiState === "toFinish"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full justify-end space-x-2 p-2",
                          },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "w-1/3",
                                attrs: { color: "cancel" },
                                on: { click: _vm.close },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "base-button",
                              {
                                staticClass: "w-1/3",
                                attrs: {
                                  color: "dark",
                                  disabled: _vm.journalIsSaving,
                                },
                                on: { click: _vm.finish },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isChatMeeting ? "Sign" : "Send"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1561092045
          ),
        },
        [
          _c("div", { staticClass: "relative" }, [
            _vm.uiState === "finishing"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "relative flex min-h-full flex-col justify-center space-y-4 text-center",
                    style: { minHeight: "70vh" },
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto mt-6 h-1/5 w-1/3 object-cover",
                      attrs: { src: require("@/assets/svg/playing_fetch.svg") },
                    }),
                    _c("base-spinner", {
                      staticClass: "mx-auto",
                      attrs: {
                        loading: _vm.uiState === "finishing",
                        size: "xl",
                      },
                    }),
                    _c("p", [_vm._v("Finishing up..")]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.uiState === "error",
                    expression: "uiState === 'error'",
                  },
                ],
                staticClass:
                  "relative flex min-h-full flex-col justify-center space-y-4 text-center",
                style: { minHeight: "70vh" },
              },
              [
                _c(
                  "p",
                  { staticClass: "rounded bg-red-100 p-4 text-red-900" },
                  [_vm._v(" " + _vm._s(_vm.error) + " ")]
                ),
                _c("p", [
                  _vm._v("Please submit a support ticket on the issue."),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.uiState === "finished",
                    expression: "uiState === 'finished'",
                  },
                ],
                staticClass:
                  "flex flex-col justify-center space-y-4 text-center",
                style: { minHeight: "70vh" },
                attrs: { id: "finishModal" },
              },
              [
                _c(
                  "p",
                  { staticClass: "mt-2 font-display text-3xl font-black" },
                  [_vm._v(" Nice one " + _vm._s(_vm.user.first_name) + "! ")]
                ),
                _c("p", { staticClass: "text-lg" }, [
                  _vm._v("The journal has been sent to the animal owner."),
                ]),
                _c("img", {
                  staticClass: "mx-auto mt-6 h-2/5 w-2/3 object-cover",
                  attrs: {
                    src: require("@/assets/svg/undraw_relaxing_walk_mljx.svg"),
                  },
                }),
                _c("p", { staticClass: "text-lg" }, [
                  _vm._v(" Yet another satisfied customer, "),
                  _c("span", { staticClass: "font-semibold text-primary" }, [
                    _vm._v("amazing job 👍"),
                  ]),
                  _vm._v("! "),
                ]),
                _c("p", { staticClass: "text-lg" }, [
                  _vm._v(" You have already helped Firstvet users with "),
                  _c("span", {
                    staticClass: "font-display text-3xl font-black",
                    attrs: { id: "counter" },
                  }),
                  _vm._v(" cases. "),
                ]),
                _c("canvas", {
                  staticClass: "absolute inset-0 hidden h-full w-full",
                  attrs: { id: "confetti-canvas" },
                }),
              ]
            ),
            _vm.uiState === "toFinish"
              ? _c(
                  "div",
                  { staticClass: "flex flex-col space-y-4" },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-4 font-display text-xl font-black tracking-wide text-gray-900",
                      },
                      [_vm._v(" Journal preview ")]
                    ),
                    _c("Journal", {
                      attrs: {
                        journal: _vm.journal,
                        "appointment-type": _vm.appointment.appointment_type,
                        "user-name":
                          _vm.appointment.booking.veterinary.display_name,
                        "user-avatar":
                          _vm.appointment.booking.veterinary.avatar || "",
                        "filtered-additional-info": _vm.radioFields,
                        "product-recommendations": _vm.productRecommendations,
                      },
                    }),
                    _vm.isChatMeeting
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "w-full rounded-lg bg-gray-200 bg-opacity-50 px-4 py-3 text-navy",
                          },
                          [
                            _c("title-row", [
                              _vm._v(" Send journal email to customer?"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-2 flex max-h-full items-center",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-2 text-sm",
                                    class: { "text-gray-500": _vm.sendEmail },
                                    attrs: { for: "copy-images" },
                                  },
                                  [_vm._v(" No ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-switch max-h-full" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sendEmail,
                                          expression: "sendEmail",
                                        },
                                      ],
                                      staticClass: "form-switch-checkbox",
                                      attrs: {
                                        id: "copy-images",
                                        type: "checkbox",
                                        name: "sign",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.sendEmail)
                                          ? _vm._i(_vm.sendEmail, null) > -1
                                          : _vm.sendEmail,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.sendEmail,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.sendEmail = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.sendEmail = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.sendEmail = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      staticClass:
                                        "form-switch-label display-none",
                                      attrs: { for: "copy-images" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    class: { "text-gray-500": !_vm.sendEmail },
                                    attrs: { for: "copy-images" },
                                  },
                                  [_vm._v(" Yes ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }