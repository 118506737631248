<template>
  <div
    class="border rounded p-3 mt-2 w-full relative"
  >
    <spinner-overlay
      v-if="clinicIsLoading"
      color="transparent"
      :loading="clinicIsLoading"
      class="flex justify-center items-center"
    />

    <div
      v-if="clinic"
      class="space-y-6"
    >
      <div class="font-bold flex justify-between text-base">
        <p class="font-display text-lg tracking-wide">
          {{ clinic.name }}
        </p>
      </div>
      <div>
        <p class="mb-2">
          <span class="font-semibold">Email:</span> {{ clinic.referral_email || clinic.email }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Tel:</span> {{ clinic.phone }}
        </p>
        <p>
          <span class="font-semibold">Address:</span> 
          {{ clinic.adress }}
          {{ clinic.postcode ? `, ${clinic.postcode}` : '' }}
          {{ clinic.city ? ', ' + clinic.city : '' }}
        </p>
      </div>
      <div class="flex justify-end">
        <text-button
          color="primary"
          type="button"
          @click="copyClinicData"
        >
          <div class="inline-flex items-center space-x-2">
            <fv-icon :icon="copied ? 'check-mark' : 'copy'" />
            <span class="text-sm">Copy contact information</span>
          </div>
        </text-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    clinic: {
      type: [null || Object],
      default: null,
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  computed: {
    ...mapState('clinicMap', ['clinicIsLoading']),
  },

  methods: {
    deSelectClinic() {
      this.$emit('clinic-deselected');
    },

    copyClinicData() {
      const { name, adress, phone } = this.clinic;
      const contactInfo = `${name}\nAddress: ${adress}\nPhone: ${phone}`;
      const el = document.createElement('textarea');
      el.value = contactInfo;
      document.body.appendChild(el);
      el.focus();
      el.select();

      try {
        const successful = document.execCommand('copy');

        this.$notify({
          group: successful ? 'primary' : 'error',
          text: successful
            ? 'Copied to clipboard'
            : 'Could not copy to clipboard',
        });
        if (successful) {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 5000);
        }
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Error',
          text: `Could not copy to clipboard: ${error}`,
        });
      }
      document.body.removeChild(el);
    },
  },
};
</script>
